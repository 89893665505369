import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import { UploadOutlined } from '@ant-design/icons';
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, Image, Upload, Select, message } from 'antd';
 
function PicManager({raw_data, doors}){
    const [fileToUpload, setFileToUpload] = useState(null)
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [picList, setPicList] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const [rawData, setRawData] = useState([])
    const [doorData, setDoorData] = useState([])
    const columns4 = [
    {
        title: '序号',
        dataIndex: 'seq_no',
        key: 'seq_no',
        render: (text, row) => (<Input type='number' value={text} onChange={e => handlePicSave({ ...row, seq_no: e.target.value })}></Input>),
    },
    {
        title: '描述文字',
        dataIndex: 'txt',
        key: 'txt',
        render: (text, row) => (
            <Input defaultValue={text} onChange={e => handlePicSave({ ...row, txt: e.target.value })}></Input>
        ),
    },
    {
        title: '英文描述文字',
        dataIndex: 'txtWithEN',
        key: 'txtWithEN',
        render: (text, row) => (
            <Input defaultValue={text} onChange={e => handlePicSave({ ...row, txtWithEN: e.target.value })}></Input>
        ),
    },
    {
        title: '链接',
        dataIndex: 'link',
        key: 'link',
        render: (text, row) => (
            <Input defaultValue={text} onChange={e => handlePicSave({ ...row, link: e.target.value })}></Input>
        ),
    },
    {
        title: '图片',
        dataIndex: 'img_url',
        key: 'img_url',
        render: (text, row) => (
            <Image width='50px'
              src={`https://images.weserv.nl/?url=${config.HOST}/${text}`}
            />
        ),
    },
    {
        title: '操作',
        width: 120,
        dataIndex: 'operation3',
        key: 'operation3',
        render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleImageUpdate(row.id)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
              <Popconfirm title="确定删除吗?" onConfirm={() => handlePicDelete(row.id)}>
                <a className='delete' title="删除">
                  <DeleteOutlined className='edit-icon' />
                </a>
              </Popconfirm>
            </div>
         ),
    }];

    useEffect(()=>{        
        handlePullPicData()
    }, [])

    const handleImageUpdate = (key) => {
        const index = picList.findIndex(item => item.id == key);
        if(index>-1){
            var tmp = picList[index]
            if(!tmp.txt){
                tmp.txt=""
            }
            if(!tmp.link){
                tmp.link=""
            }
            if(!tmp.txtWithEN){
                tmp.txtWithEN=""
            }
            if(!tmp.seq_no){
                tmp.seq_no=0
            }
        axios.post(config.APIS_URL.UPDATE_MGR_ADS, tmp).then(res=>{
            if(res.data.code==200){
                message.success('修改成功')
                setPicList(picList)
                console.log(res)
            }
            else{
                message.warning("修改失败")
            }
        }).catch(ex=>{
            console.error(ex)
        })
        }
    }

    const handlePicDelete = key => {
        const index = picList.findIndex(item => item.id == key);
        if(index>-1){
        axios.delete(`${config.APIS_URL.DEL_MGR_ADS}/${key}`).then(res=>{
            if(res.data.code==200){
                message.success('删除成功')
                handlePullPicData()
            }
            else{
                message.warning('删除失败')
            }
        }).catch(ex=>{
            console.error(ex)
        })
        }
    }

    const handlePullPicData = () => {
        setLoading(true)
        axios.post(config.APIS_URL.GET_MGR_ADS).then(res=>{
        if(res.data.code==200){
            setPicList(res.data.list)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }
    const resetSate = () => {
        var temp = newData
        Object.keys(temp).forEach(function(key) {
            delete temp[key];
          });
        setNewDataA(temp)
        setFileToUpload(null)
    }

    const handleUpload= ()=>{
        if(newData.txt=='' 
            || fileToUpload==null){
          message.warning('请完全输入数据')
          return
        }
        if(!newData.link){
            newData.link = ""
        }
        if(!newData.txtWithEN){
            newData.txtWithEN = ""
        }
        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append("json", JSON.stringify(newData))

        axios.post(config.APIS_URL.ADD_MGR_ADS, formData).then(res=>{
            if(res.data.code==200){
                message.success("添加成功")
                handlePullPicData()
                setOpen(false)
                setnewData({})
            }
            else{
                message.warning('添加失败')
            }
            setTableKey(tableKey+1)
        }).catch(ex=>{
            console.error(ex)
            setOpen(false)
            setTableKey(tableKey+1)
        })
    }

    const handlePicSave = row => {
        const newData = [... picList];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
        ...item,
        ...row,
        });
        setPicList(newData)
    };
    const setnewData = (e, index)=>{
        var data = newData
        if(index=="txt"){
            data.txt = e.target.value
        }
        else if(index=="link"){
            data.link = e.target.value
        }
        else if(index=="txten"){
            data.txtWithEN = e.target.value
        }
        setNewDataA(data)
    }

    const beforeUpload = file => {
        if(file.type!="image/jpg" && file.type!="image/jpeg" && file.type!="image/png"){
            message.warning("仅支持jpg、png图片")
            return Upload.LIST_IGNORE
        } 

        setFileToUpload(file)
        return false
    }

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>合作伙伴管理</div>
                    <Button onClick={()=>{setOpen(true); resetSate();}} type="primary">添加一行</Button>
                </div>
                <Table loading={loading} dataSource={picList} 
                    columns={columns4} />
            </div>
        
            <Modal
                title="添加数据"
                centered
                onCancel={()=>{setOpen(false); setnewData({}); setTableKey(tableKey+1)}}
                onOk={()=>{handleUpload()}}
                open={open}
                width={1000}>
                <table key={tableKey} style={{width: '95%'}}>
                    <tbody>
                        <tr>
                            <th>描述文字</th><th>英文描述文字</th><th>链接</th><th>图片</th>
                        </tr>
                        <tr>
                            <td style={{width: '30%'}}><Input.TextArea key="1" defaultValue={newData.txt} onChange={(value)=>setnewData(value, 'txt')}></Input.TextArea></td>
                            <td style={{width: '30%'}}><Input.TextArea key="1" defaultValue={newData.txt} onChange={(value)=>setnewData(value, 'txten')}></Input.TextArea></td>
                            <td style={{width: '30%'}}><Input key="4" defaultValue='' onChange={(value)=>setnewData(value, 'link')}></Input></td>
                            <td style={{width: '150px', display: 'block', border: 'none'}}><Upload
                                accept="image/png, image/jpeg, image/jpg"
                                maxCount={1}
                                beforeUpload={(file=>{beforeUpload(file)})}
                                onRemove={()=>{setFileToUpload(null)}}
                                fileList={fileToUpload ? [fileToUpload] : []}
                            ><Button icon={<UploadOutlined />}>选择图片</ Button></Upload></td>
                        </tr>
                    </tbody>
                </table>
            </Modal>
        </div>
    );
}
 
export default PicManager;