import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Image, Upload, Checkbox, Spin, Select } from 'antd';
import "./cargo.less"
 
function CargoManager(){
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [bigloading, setBigLoading] = useState(false)
    const columns2 = [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id-1',
          render: (text, row) => (
            <div>{text}</div>
          ),
        },
        {
          title: '成份(搜索)',
          dataIndex: 'raw_search',
          key: 'raw_search',
          render: (text, row) => (
            <Input
              title={text}
              value={text}
              onChange={e => handleDoorSave({ ...row, raw_search: e.target.value })}
            />
          ),
        },
        {
            title: '成份',
            dataIndex: 'raw_name',
            key: 'raw_name',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, raw_name: e.target.value })}
              />
            ),
        },
        {
            title: '规格',
            dataIndex: 'specification',
            key: 'specification',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, specification: e.target.value })}
              />
            ),
        },
        {
            title: '门幅',
            dataIndex: 'width',
            key: 'width',
            render: (text, row) => (
              <Input
                title={text}
                type="number"
                value={text}
                onChange={e => handleDoorSave({ ...row, width: e.target.value })}
              />
            ),
        },
        {
            title: 'GMS',
            dataIndex: 'gms',
            key: 'gms',
            render: (text, row) => (
              <Input
              title={text}
                type="number"
                value={text}
                onChange={e => handleDoorSave({ ...row, gms: e.target.value })}
              />
            ),
        },
        {
            title: '难度',
            dataIndex: 'difficulty',
            key: 'difficulty',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, difficulty: e.target.value })}
              />
            ),
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            render: (text, row) => (
              <Input
                title={text}
                type="number"
                value={text}
                onChange={e => handleDoorSave({ ...row, price: e.target.value })}
              />
            ),
        },
        {
            title: 'fob单价',
            dataIndex: 'fob_price',
            key: 'fob_price',
            render: (text, row) => (
              <Input
                title={text}
                type="number"
                value={text}
                onChange={e => handleDoorSave({ ...row, fob_price: e.target.value })}
              />
            ),
        },
        {
            title: '可剪样',
            dataIndex: 'is_cut',
            key: 'ic_ut',
            render: (text, row) => (
              <Select
                key={`${row.id}-${text}`}
                options={YesNo}
                defaultValue={YesNo[text]?.label}
                onChange={value => handleDoorSave({ ...row, is_cut: value })}
              />
            ),
        },
        {
            title: '风格',
            dataIndex: 'style',
            key: 'style',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, style: e.target.value })}
              />
            ),
        },
        {
            title: '用途',
            dataIndex: 'usages',
            key: 'usages',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, usages: e.target.value })}
              />
            ),
        },
        {
            title: '季节',
            dataIndex: 'season',
            key: 'season',
            render: (text, row) => (
              <Input
                title={text}
                value={text}
                onChange={e => handleDoorSave({ ...row, season: e.target.value })}
              />
            ),
        },
        {
            title: '图样',
            dataIndex: 'id',
            key: 'url',
            render: (text, row) => (
              <Image
                style={{width: '60px', height:'60px'}}
                src={`https://img.qifu2023.com.cn/mall/${text}.jpg`}
              />
            ),
        },
        {
          title: '操作',
          dataIndex: 'operation2',
          key: 'operation2',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleDoowUpdate(row.id)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];
    const YesNo = [{label: "yes", value: 1}, {label: "no", value: 0}]
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedKeys(selectedRowKeys)
        setSelectedRows(selectedRows)
    };

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_CARGO_INFO).then(res=>{
        if(res.data.code==200){
            let temp = res.data.list
            temp.map((m,i)=>m.key=m.id)
            setDataList(temp)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoowUpdate = key => {
        const index = dataList.findIndex(item => item.id == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_CARGO, dataList[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    const selection = {
        selectedRowKeys,
        onChange: onSelectChange
    }
    const handleClearData = () => {
      Modal.confirm({
        title:"删除",
        content: "是否删除所有？",
        onOk:()=>{
            axios.delete(`${config.APIS_URL.CLEAR_CARGO}`).then(res=>{
                if(res.data.code==200){
                    setSelectedKeys([])
                    message.success('删除成功')
                    handlePullData()
                }
                else{
                  message.error('删除失败')
                }
              }).catch(ex=>{
                console.error(ex)
                message.error(ex.message)
              })
        }
      })
    }
    const handleRemoveData = () =>{
        console.log(selectedRows)
        
        if(selectedRows.length<=0){
            message.warning("还没有选中任何一行")
            return
        }
        if(selectedRows.length>0){
          Modal.confirm({
            title:"删除",
            content: "是否删除该选项？",
            onOk:()=>{
                axios.post(`${config.APIS_URL.REMOVE_CARGO_IDS}`, {rows: selectedRows}).then(res=>{
                    if(res.data.code==200){
                        setSelectedKeys([])
                        message.success('删除成功')
                        handlePullData()
                    }
                    else{
                      message.error('删除失败')
                    }
                  }).catch(ex=>{
                    console.error(ex)
                    message.error(ex.message)
                  })
            }
          })
        }
    }
    
    const handleDoorSave = row => {
        const tempdata = [...dataList];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataList(tempdata)
    };
    const begoreImageUpload = (file) => {
        if(file.type != "application/x-zip-compressed"){
            message.error("必须是压缩包文件")
            return
        }
        const formData = new FormData();
        formData.append('file', file);
        setBigLoading(true)
        axios.post(config.APIS_URL.UPLOAD_MGR_IMAGE, formData).then(res=>{
          if(res.status==200 && res.data.code==200){
            var tips = ["上传成功"]
            if(res.data.duplicate>0){
              tips.push(`重复${res.data.duplicate}张图片`)
            }
            // if(res.data.duplicate2>0){
            //   tips.push(`重复${res.data.duplicate2}条数据`)
            // }
            message.success(tips.join(", "))
            handlePullData()
          }
          else{
            message.error("网络错误")
          }
          setBigLoading(false)
        }).catch(ex=>{
          message.error(ex)
          setBigLoading(false)
        })
        console.log(file)
    }
    const beforeExcelUpload = (file) => {
        if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
          message.error("必须是xlsx文件")
          return
        }
  
        const formData = new FormData();
        formData.append('file', file);
        setBigLoading(true)
        axios.post(config.APIS_URL.UPLOAD_MGR_EXCEL, formData).then(res=>{
          if(res.status==200 && res.data.code==200){
            var tips = ["上传成功"]
            if(res.data.duplicate>0){
              tips.push(`数据重复${res.data.duplicate}条数据`)
            }
            message.success(tips.join(", "))
            handlePullData()
          }
          else{
            message.error("网络错误")
          }
          setBigLoading(false)
        }).catch(ex=>{
          message.error(ex)
          setBigLoading(false)
        })
        console.log(file)
      }

    return (
        <div className='containter-box-1'>
            <Spin spinning={bigloading} size='large' tip="上传中，请不要关闭或刷新页面">
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>货物管理</div>
                    <div style={{display:'flex'}}>
                        <Button style={{marginRight: '15px'}} onClick={()=>{handleClearData()}} type="primary" danger>删除所有</Button>
                        <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                            beforeUpload={beforeExcelUpload}>
                            <Button style={{marginRight: '20px'}} type="primary">导入Excel</Button>
                        </Upload>
                        <Upload maxCount={1} accept='application/x-zip-compressed' 
                            beforeUpload={begoreImageUpload}>
                            <Button style={{marginRight: '20px'}} type="primary">导入图片</Button>
                        </Upload>
                        <Button onClick={()=>{handleRemoveData()}} type="primary" danger>删除选中项</Button>
                    </div>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={dataList} 
                    rowSelection={selection}
                    columns={columns2} />
            </Spin>
        </div>
    );
}
 
export default CargoManager;