import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message } from 'antd';
 
function COEManager(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [doorData, setDoorData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const columns2 = [
        {
            title: '难易程度',
            dataIndex: 'difficulty',
            key: 'difficulty_k',
            render: (text, row) => (
              <div>{config.DIFFICULTY[text]}</div>
            ),
          },
        {
          title: '系数K',
          dataIndex: 'k',
          key: 'k',
          render: (text, row) => (
            <Input
              value={text}
              type='number'
              onChange={e => handleDoorSave({ ...row, k: e.target.value })}
            />
          ),
        },
        {
          title: '系数P',
          dataIndex: 'p',
          key: 'p',
          render: (text, row) => (
            <Input
              type="number"
              value={text}
              onChange={e => handleDoorSave({ ...row, p: e.target.value })}
            />
          ),
        },
        {
          title: '操作',
          dataIndex: 'operation2',
          width: 120,
          key: 'operation2',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleDoowUpdate(row.id)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_SYS_COE_CONFIG).then(res=>{
        if(res.data.code==200){
            setDoorData(res.data.data)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoowUpdate = key => {
        const index = doorData.findIndex(item => item.id == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_SYS_COE_CONFIG, doorData[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    
    const handleDoorSave = row => {
        const tempdata = [...doorData];
        const index = tempdata.findIndex(item => row.id === item.id);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setDoorData(tempdata)
    };

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>门幅管理</div>
                </div>
                <div>计算公式：</div>
                <p>成品价格 Price = (每米克重 + 纬线密度 × <span style={{color:'red'}}>系数K</span>) × 1.03 + 2.31 + <span style={{color:'red'}}>系数P</span></p>
                <p>FOB价格 fob_Price = 成品价格 Price × 0.9144/(货币兑换利率 × 1.08)</p>
                <Table className='manager-table' bordered loading={loading} dataSource={doorData} 
                    columns={columns2} />
            </div>
        </div>
    );
}
 
export default COEManager;