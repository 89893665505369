import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import {EditOutlined, SearchOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Image, Upload, Checkbox, Spin, Form } from 'antd';
import "./cargo.less"

function OrderManager(){
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState(null)
    const [bigloading, setBigLoading] = useState(false)
    const columns2 = [
        {
          title: '订单号',
          dataIndex: 'order_id',
          key: 'id-1',
          render: (text, row) => (
            <div title={text}>{text}</div>
          ),
        },
        {
          title: '账号',
          dataIndex: 'user_id',
          key: 'userid-1',
          render: (text, row) => (
            <div title={text}>{text}</div>
          ),
        },
        {
          title: '编号',
          dataIndex: 'comm_id',
          key: 'comm_id',
          render: (text, row) => (
            <div title={text}>{text}</div>
          ),
        },
        {
            title: '数量',
            dataIndex: 'cnt',
            key: 'cnt',
            render: (text, row) => (
              <div title={text}>{text}</div>
            ),
        },
        {
            title: '支付金额',
            dataIndex: 'money',
            key: 'money',
            render: (text, row) => (
              <div title={text}>{text}</div>
            ),
        },
        {
          title: '总支付金额',
          dataIndex: 'amount',
          key: 'amount',
          render: (text, row) => (
            <div title={text}>{text}</div>
          ),
      },
        {
            title: '收件人',
            dataIndex: 'addressee',
            key: 'addressee',
            render: (text, row) => (
                <div title={text}>{text}</div>
            ),
        },
        {
            title: '手机号',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
            render: (text, row) => (
                <div title={text}>{text}</div>
            ),
        },
        {
            title: '收件地址',
            dataIndex: 'address',
            key: 'address',
            render: (text, row) => (
                <div title={text}>{text}</div>
            ),
        },
        {
            title: '下单时间',
            dataIndex: 'created_date',
            key: 'created_date',
            render: (text, row) => (
                <div title={text}>{text}</div>
            ),
        },
        {
            title: '是否支付',
            dataIndex: 'is_payed',
            key: 'is_payed',
            render: (text, row) => (
                <div title={text}>{text?'是':"否"}</div>
            ),
        },
        {
            title: '支付时间',
            dataIndex: 'pay_date',
            key: 'pay_date',
            render: (text, row) => (
                <div title={Boolean(row.is_payed)?text:null}>{Boolean(row.is_payed)?text:null}</div>
            ),
        },
        {
            title: '快递单号',
            dataIndex: 'tracking_no',
            key: 'tracking_no',
            render: (text, row) => (
                <Input
                    title={text}
                    value={text}
                    onChange={e => handleDoorSave({ ...row, tracking_no: e.target.value })}
                  />
              
            ),
        },
        {
            title: '快递类型',
            dataIndex: 'tracking_type',
            key: 'tracking_type',
            render: (text, row) => (
                <Input
                    title={text}
                    value={text}
                    onChange={e => handleDoorSave({ ...row, tracking_type: e.target.value })}
                  />
            ),
        },
        {
          title: '发货',
          dataIndex: 'operation2',
          key: 'operation2',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title={row.is_payed?(row.tracking_no?"已经发货啦，确定修改发货信息吗？":'确定填写无误?'):'对方还没有付款，确定发货吗'} onConfirm={() => handleDoowUpdate(row.order_id)}>
                <a className='edit' title="发货">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];

    useEffect(()=>{       
        handlePullData({})
    }, [])
    
    const handlePullData = (param) => {
        setLoading(true)
        axios.post(config.APIS_URL.GET_MGR_ORDER_LIST, param).then(res=>{
        if(res.data.code==200){
            let temp = res.data.list
            temp.map((m,i)=>m.key=m.id)
            setDataList(temp)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoowUpdate = key => {
        const index = dataList.findIndex(item => item.order_id == key);
        if(index>-1){
          if(!dataList[index].tracking_no || !dataList[index].tracking_type){
            message.error("请确认填写无误")
            return
            }  

          axios.post(config.APIS_URL.UPDATE_MGR_ORDER_STATUS, dataList[index]).then(res=>{
            if(res.data.code==200){
              message.success('状态修改成功')
              handlePullData({})
            }
            else{
                message.error('状态修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    
    const handleDoorSave = row => {
        const tempdata = [...dataList];
        const index = tempdata.findIndex(item => row.order_id === item.order_id);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataList(tempdata)
    };
    const handleSearchEvevt = e => {
      const {search_account} = e
      console.log(search_account)
      var param = {}
      if(!search_account || search_account.trim()=="") {
        
      }
      else {
        param.search_account = search_account
      }
      handlePullData(param)
    }
    
    return (
        <div className='containter-box-1'>
            <Spin spinning={bigloading} size='large' tip="上传中，请不要关闭或刷新页面">
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>订单管理</div>
                </div>
                <div>
                  <Form style={{display:'flex'}} onFinish={(e)=>handleSearchEvevt(e)}>
                    <Form.Item name="search_account" label="下单人">
                      <Input value={searchText} onChange={(e)=>{setSearchText(e.target.value)}}></Input>
                    </Form.Item>
                    <Button htmlType='submit' icon={<SearchOutlined />}>搜索</Button>
                  </Form>
                </div>
                
                <Table className='manager-table' bordered loading={loading} dataSource={dataList} 
                    columns={columns2} />
            </Spin>
        </div>
    )
}
export default OrderManager
