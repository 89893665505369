import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Upload, Select } from 'antd';
 
function LevelMgr(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [productData, setProductData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const longlatiList = [{label: '经线', value:0}, {label: '纬线', value: 1}]

    const handleSelectSave = (row, value)=>{
      let temp = [...productData]
      let idx = temp.findIndex(f=>f.id==row.id)
      temp[idx] = row
      setProductData(temp)
    }
    const columns = [
      {
        title: '提花类型',
        dataIndex: 'product_type',
        key: 'product_type',
        defaultSortOrder: '',
        sorter: (a, b) => a.product_type - b.product_type,
        render: (text, row) => (
          <div>{text=="largeJac"?"大提花":(text=="smallJac"?"小提花":"不是提花")}</div>
        ),
      },
        {
          title: '所属类型',
          dataIndex: 'sub_type',
          key: 'sub_type',
          render: (text, row) => (
            <div>{text==0?"Low":(text==1?"High":"此类型不区分原料")}</div>
          ),
        },
        {
          title: '取值范围',
          dataIndex: 'min_value',
          key: 'min_value',
          render: (text, row) => (
            <div>
              {
                row.min_value != null?(
                  <Input
                    value={row.min_value}
                    type='number'
                    style={{width: '80px'}}
                    onChange={e => handleSave({ ...row, min_value: e.target.value })}
                  />
                ):null
              }
              {/* <span>{row.min_value != null?row.min_value:null}</span> */}
              <span>{row.min_value != null && row.max_value!=null? " - ":null}</span>
              {/* <span>{row.max_value!=null?row.max_value:null}</span> */}
              {
                row.max_value != null?(
                  <Input
                      value={row.max_value}
                      type='number'
                      style={{width: '80px'}}
                      onChange={e => handleSave({ ...row, max_value: e.target.value })}
                    />
                ):null
              }
              <span style={{marginLeft:'5px'}}>{row.min_value == null && row.max_value!=null? '以下':null}</span>
              <span style={{marginLeft:'5px'}}>{row.min_value != null && row.max_value==null? '以上':null}</span>
            </div>
          ),
        },
        {
          title: '难度等级',
          dataIndex: 'level_id',
          key: 'level_id',
          render: (text, row) => (
            <div>{config.DIFFICULTY[text]}</div>
          ),
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 80,
          key: 'operation',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleUpdate(row.id)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
              {/* <Popconfirm title="确定删除吗?" onConfirm={() => handleDelete(row.key)}>
                <a className='delete' title="删除">
                  <DeleteOutlined className='edit-icon' />
                </a>
              </Popconfirm> */}
            </div>
          ),}
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.FETCH_MGR_SCORE_LIST).then(res=>{
        if(res.data.code==200){
            setProductData(res.data.data)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleUpdate = key => {
        const index = productData.findIndex(item => item.id == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDAE_MGR_SCORE_INFO, productData[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleDelete = key =>{
        const index = productData.findIndex(item => item.key == key);
        if(index>-1){
          axios.delete(`${config.APIS_URL.DEL_MGR_PRODUCT}/${key}`).then(res=>{
            if(res.data.code==200){
              message.success('删除成功')
              handlePullData()
            }
            else{
              message.error('删除失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleAddData = ()=>{
        if(newData.raw_name==undefined || newData.product_name==undefined|| newData.dentisty==undefined || newData.price==undefined){
          message.warning('请完全输入数据')
          return
        }
        if(!newData.longLati){
          newData.longLati = 0
        }
        axios.post(config.APIS_URL.INSERT_MGR_PRODUCT, newData).then(res=>{
          console.log(res)
          if(res.data.code==200){
            message.success("添加成功")
            handlePullData()
            setOpen(false)
          }
          else{
            message.error('添加失败')
          }
          setTableKey(tableKey+1)
        }).catch(ex=>{
            setTableKey(tableKey+1)
            console.error(ex)
        })
    }

    const handleSave = row => {
        const tempdata = [...productData];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setProductData(tempdata)
    };
    const setnewDataChange = (value) => {
      var data = newData
      data.longLati = value
      setNewDataA(data)
    }
    const setnewData = (e, index)=>{
        var data = newData
        if(index=='raw_name'){
            data.raw_name = e.target.value
          }
          else if(index=='product_name'){
            data.product_name = e.target.value
          }
          else if(index=='density'){
            data.dentisty = e.target.value
          }
          else if(index=='price'){
            data.price = e.target.value
          }
          else if(index=="product_ptg"){
            data.product_ptg = e.target.value
          }
        setNewDataA(data)
    }
    const handleClear=()=>{
      Modal.warning({
        title: '警告',
        content: '一旦删除将无法找回, 是否确定要删除所有数据?',
        onOk: ()=>{
          axios.delete(`${config.APIS_URL.DEL_MGR_PRODUCT}/-1`).then(res=>{
            if(res.status==200 && res.data.code==200){
              message.success("所有数据已清空")
              handlePullData()
            }else{
              message.error("网络错误")
            }
          }).catch(ex=>{
            message.error(ex)
          })
        },
        mask: true,
        maskClosable:true,
        okCancel: true,
        okText:"删除",
        cancelText: '取消',
        okButtonProps: { style: {backgroundColor: 'red', color: "#fff"}},
        onCancel:()=>{}
      })
    }
    const beforePtgUpload = file => {
      if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
        message.error("必须是xlsx文件")
        return
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(config.APIS_URL.BATCH_MGR_PRODUCTS_PTG, formData).then(res=>{
        if(res.status==200 && res.data.code==200){
          var tips = ["上传成功"]
          message.success(tips.join(", "))
          handlePullData()
        }
        else{
          message.error("网络错误")
        }
      }).catch(ex=>{
        message.error(ex)
      })
      console.log(file)
    }
    const beforeUpload = (file) => {
      if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
        message.error("必须是xlsx文件")
        return
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(config.APIS_URL.BATCH_MGR_PRODUCTS, formData).then(res=>{
        if(res.status==200 && res.data.code==200){
          var tips = ["上传成功"]
          if(res.data.duplicate>0){
            tips.push(`经线重复${res.data.duplicate}条数据`)
          }
          if(res.data.duplicate2>0){
            tips.push(`纬线重复${res.data.duplicate2}条数据`)
          }
          message.success(tips.join(", "))
          handlePullData()
        }
        else{
          message.error("网络错误")
        }
      }).catch(ex=>{
        message.error(ex)
      })
      console.log(file)
    }

    return (
      <div className='containter-box-1'>
        <div>
            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div className='sub-title'>原料管理</div>
                <div style={{display:'flex'}}>
                  {/* <Button onClick={()=>handleClear()} danger type='primary' style={{marginRight: '20px'}}>清空数据</Button>
                  <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                    beforeUpload={beforePtgUpload}>
                    <Button style={{marginRight: '20px'}} type="primary">更新原料配比表</Button>
                  </Upload>
                  <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                    beforeUpload={beforeUpload}>
                    <Button style={{marginRight: '20px'}} type="primary">导入Excel</Button>
                  </Upload>
                  <Button onClick={()=>{setOpen(true);}} type="primary">添加一行</Button> */}
                </div>
            </div>
            <Table className='manager-table' bordered loading={loading} dataSource={productData} 
                columns={columns} />
        </div>
        <div style={{color:'red'}}>注意
            <div style={{color:"#000", display:'flex', flexDirection:'column'}}>
                <span>1. 大提花、小提花按照所选原料种类区分High、Low</span>
                <span>2. 不是提花只能选1种原料、需要按照原料区分</span>
                <span>3. High即相同的分数区间，假如High是2级，Low即为1级</span>
                <span>4. 大提花、小提花2种面料，2种纱线及以上为High、一种面料，1种纱线为Low</span>
            </div>
        </div>
    </div>
    );
}
 
export default LevelMgr;