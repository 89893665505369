import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Upload } from 'antd';
 
function FakeManager(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [doorData, setDoorData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const columns2 = [
        {
          title: '编号',
          dataIndex: 'comm_id',
          key: 'comm_id22',
          render: (text, row) => (
            <div>{text}</div>
          ),
        },
        {
          title: '售出',
          dataIndex: 'cnt',
          key: 'cnt22',
          render: (text, row) => (
            <div>{text}</div>
          ),
        }
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_FAKE_SOLD).then(res=>{
        if(res.data.code==200){
            setDoorData(res.data.list)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoorDelete = key =>{
        Modal.confirm({
            content: '是否删除？ ',
            onOk: ()=>{
                axios.delete(`${config.APIS_URL.CLEAR_FAKE_SOLD}`).then(res=>{
                    if(res.data.code==200){
                      message.success('删除成功')
                      handlePullData()
                    }
                    else{
                      message.error('删除失败')
                    }
                  }).catch(ex=>{
                    console.error(ex)
                  })
            }
        })
    }

    const beforeExcelUpload = (file) => {
        if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
          message.error("必须是xlsx文件")
          return
        }
  
        const formData = new FormData();
        formData.append('file', file);
       
        axios.post(config.APIS_URL.UPLOAD_FAKE_SOLD, formData).then(res=>{
          if(res.status==200 && res.data.code==200){
            var tips = ["上传成功"]
            
            message.success(tips.join(", "))
            handlePullData()
          }
          else{
            message.error("网络错误")
          }
        }).catch(ex=>{
          message.error(ex)
        })
        console.log(file)
      }

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>虚拟售出数据</div>
                    <div style={{display:'flex'}}>
                        <Button style={{marginRight: '15px'}} onClick={()=>{handleDoorDelete()}} type="primary" danger>一键删除</Button>
                        <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                            beforeUpload={beforeExcelUpload}>
                            <Button style={{marginRight: '20px'}} type="primary">导入Excel</Button>
                        </Upload>
                        
                    </div>
                    
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={doorData} 
                    columns={columns2} />
            </div>
        </div>
    );
}
 
export default FakeManager;