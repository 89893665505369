import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message } from 'antd';
 
function RateManager(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [rateData, setRateData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const columns3 = [
        {
            title: '国家代码',
            dataIndex: 'country_code',
            key: 'country_code',
            render: (text, row) => (
                <Input
                value={text}
                onChange={e => handleCountrySave({ ...row, country_code: e.target.value })}
                />
            ),
        },
        {
            title: '汇率',
            dataIndex: 'rate',
            key: 'country_rate',
            render: (text, row) => (
                <Input
                type="number"
                value={text}
                onChange={e => handleCountrySave({ ...row, rate: e.target.value })}
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'operation3',
            width: 120,
            key: 'operation3',
            render: (text, row) => (
                <div className='edit-box'>
                    <Popconfirm title="确定更新?" onConfirm={() => handleRateUpdate(row.key)}>
                        <a className='edit' title="编辑">
                            <EditOutlined className='edit-icon'></EditOutlined>
                        </a>
                    </Popconfirm>
                    <Popconfirm title="确定删除吗?" onConfirm={() => handleRateDelete(row.key)}>
                        <a className='delete' title="删除">
                            <DeleteOutlined className='edit-icon' />
                        </a>
                    </Popconfirm>
                </div>
            ),
        },
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_MGR_PRODUCT).then(res=>{
        if(res.data.code==200){
            setRateData(res.data.rate_list)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleRateUpdate = key => {
        const index = rateData.findIndex(item => item.key == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_RATE, rateData[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleRateDelete = key =>{
        const index = rateData.findIndex(item => item.key == key);
        if(index>-1){
          axios.delete(`${config.APIS_URL.DEL_MGR_RATE}/${key}`).then(res=>{
            if(res.data.code==200){
              message.success('删除成功')
              handlePullData()
            }
            else{
              message.error('删除失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    const handleRatesAddData = ()=>{
        if(newData.country_code==undefined || newData.rate==undefined){
          message.warning('请完全输入数据')
          return
        }
        axios.post(config.APIS_URL.INSERT_MGR_RATE, newData).then(res=>{
          console.log(res)
          if(res.data.code==200){
            message.success("添加成功")
            handlePullData()
            setOpen(false)
          }
          else{
            message.error('添加失败')
          }
          setTableKey(tableKey+1)
        }).catch(ex=>{
            setTableKey(tableKey+1)
            console.error(ex)
        })
    }
    const handleCountrySave = row => {
        const tempdata = [...rateData];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setRateData(tempdata)
    };

    
    const setnewData = (e, index)=>{
        var data = newData
        if(index=='country_code'){
            data.country_code = e.target.value
        }
        else if(index=='rate'){
            data.rate = e.target.value
        }
        setNewDataA(data)
    }

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>汇率管理</div>
                    <Button onClick={()=>{setOpen(true);}} type="primary">添加一行</Button>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={rateData} 
                    columns={columns3} />
            </div>
        
            <Modal
                title="添加汇率"
                centered
                onCancel={()=>{setOpen(false); setTableKey(tableKey+1)}}
                onOk={()=>handleRatesAddData()}
                open={open}
                width={1000}
            >
                <table className='manager' key={tableKey}>
                    <tbody>
                        <tr><th>国家代码</th><th>汇率</th></tr>
                        <tr>
                            <td><Input onChange={(value)=>setnewData(value, 'country_code')}></Input></td>
                            <td><Input type='number' onChange={(value)=>setnewData(value, 'rate')}></Input></td>
                        </tr>
                    </tbody>
                </table>
          </Modal>
        </div>
    );
}
 
export default RateManager;