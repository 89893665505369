import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import ArgsMgr from './args';
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Upload, Select } from 'antd';
 
function ProductMgr(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [productData, setProductData] = useState([])
    const [sourceData, setSourceData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const longlatiList = [{label: '经线', value:0}, {label: '纬线', value: 1}]

    const handleSelectSave = (row, value)=>{
      let temp = [...productData]
      let idx = temp.findIndex(f=>f.id==row.id)
      temp[idx] = row
      setProductData(temp)
    }
    const columns = [
      {
        title: '经纬线',
        dataIndex: 'longLati',
        key: 'longLati',
        defaultSortOrder: '',
        sorter: (a, b) => a.longLati - b.longLati,
        render: (text, row) => (
          <Select
            options={longlatiList}
            defaultValue={(text==0 || text==1)?longlatiList[text].label:null}
            onChange={e => handleSelectSave({ ...row, longLati: e })}
          ></Select>
        ),
      },
        {
          title: '原料名',
          dataIndex: 'raw_name',
          key: 'raw_name',
          render: (text, row) => (
            <Input
              value={text}
              onChange={e => handleSave({ ...row, raw_name: e.target.value })}
            />
          ),
        },
        {
          title: '产品名',
          dataIndex: 'product_name',
          key: 'product_name',
          render: (text, row) => (
            <Input
              value={text}
              onChange={e => handleSave({ ...row, product_name: e.target.value })}
            />
          ),
        },
        {
          title: '原料成分',
          dataIndex: 'product_ptg',
          key: 'product_ptg',
          render: (text, row) => (
            <div>{text}</div>
            // <Input value={text}
            //   onChange={e => handleSave({ ...row, product_ptg: e.target.value })}
            // />
          ),
        },
        {
          title: '规格',
          dataIndex: 'dentisty',
          key: 'dentisty',
          render: (text, row) => (
            <Input
              value={text}
              type='number'
              onChange={e => handleSave({ ...row, dentisty: e.target.value })}
            />
          ),
        },
        {
          title: '价格',
          dataIndex: 'price',
          key: 'price',
          render: (text, row) => (
            <Input
              value={text}
              type='number'
              onChange={e => handleSave({ ...row, price: e.target.value })}
            />
          ),
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          key: 'operation',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleUpdate(row.key)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
              <Popconfirm title="确定删除吗?" onConfirm={() => handleDelete(row.key)}>
                <a className='delete' title="删除">
                  <DeleteOutlined className='edit-icon' />
                </a>
              </Popconfirm>
            </div>
          ),
        },
      ];

    useEffect(()=>{       
        handlePullData()
        handlePullSourceData()
    }, [])

    const handlePullSourceData = () => {
      setLoading(true)
      axios.get(config.APIS_URL.GET_TEST).then(res=>{
      if(res.data.code==200){
          setSourceData(res.data.list)
      }
      else{
          message.warning('数据错误')
      }
      setLoading(false)
      }).catch(ex=>{
          setLoading(false)
          console.log('网络错误')
      })
  }
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_MGR_PRODUCT).then(res=>{
        if(res.data.code==200){
            setProductData(res.data.product_list)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleUpdate = key => {
        const index = productData.findIndex(item => item.key == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_PRODUCT, productData[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleDelete = key =>{
        const index = productData.findIndex(item => item.key == key);
        if(index>-1){
          axios.delete(`${config.APIS_URL.DEL_MGR_PRODUCT}/${key}`).then(res=>{
            if(res.data.code==200){
              message.success('删除成功')
              handlePullData()
            }
            else{
              message.error('删除失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleAddData = ()=>{
        if(newData.raw_name==undefined || newData.product_name==undefined|| newData.dentisty==undefined || newData.price==undefined){
          message.warning('请完全输入数据')
          return
        }
        if(!newData.longLati){
          newData.longLati = 0
        }
        axios.post(config.APIS_URL.INSERT_MGR_PRODUCT, newData).then(res=>{
          console.log(res)
          if(res.data.code==200){
            message.success("添加成功")
            handlePullData()
            setOpen(false)
          }
          else{
            message.error('添加失败')
          }
          setTableKey(tableKey+1)
        }).catch(ex=>{
            setTableKey(tableKey+1)
            console.error(ex)
        })
    }

    const handleSave = row => {
        const tempdata = [...productData];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setProductData(tempdata)
    };
    const setnewDataChange = (value) => {
      var data = newData
      data.longLati = value
      setNewDataA(data)
    }
    const setnewData = (e, index)=>{
        var data = newData
        if(index=='raw_name'){
            data.raw_name = e.target.value
          }
          else if(index=='product_name'){
            data.product_name = e.target.value
          }
          else if(index=='density'){
            data.dentisty = e.target.value
          }
          else if(index=='price'){
            data.price = e.target.value
          }
          else if(index=="product_ptg"){
            data.product_ptg = e.target.value
          }
        setNewDataA(data)
    }
    const handleSourceClear=()=>{
      Modal.warning({
        title: '警告',
        content: '一旦删除将无法找回, 是否确定要删除所有数据?',
        onOk: ()=>{
          axios.delete(`${config.APIS_URL.DELETE_TEST}`).then(res=>{
            if(res.status==200 && res.data.code==200){
              message.success("所有数据已清空")
              handlePullSourceData()
            }else{
              message.error("网络错误")
            }
          }).catch(ex=>{
            message.error(ex)
          })
        },
        mask: true,
        maskClosable:true,
        okCancel: true,
        okText:"删除",
        cancelText: '取消',
        okButtonProps: { style: {backgroundColor: 'red', color: "#fff"}},
        onCancel:()=>{}
      })
    }
    const handleClear=()=>{
      Modal.warning({
        title: '警告',
        content: '一旦删除将无法找回, 是否确定要删除所有数据?',
        onOk: ()=>{
          axios.delete(`${config.APIS_URL.DEL_MGR_PRODUCT}/-1`).then(res=>{
            if(res.status==200 && res.data.code==200){
              message.success("所有数据已清空")
              handlePullData()
            }else{
              message.error("网络错误")
            }
          }).catch(ex=>{
            message.error(ex)
          })
        },
        mask: true,
        maskClosable:true,
        okCancel: true,
        okText:"删除",
        cancelText: '取消',
        okButtonProps: { style: {backgroundColor: 'red', color: "#fff"}},
        onCancel:()=>{}
      })
    }
    const beforePtgUpload = file => {
      if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
        message.error("必须是xlsx文件")
        return
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(config.APIS_URL.BATCH_MGR_PRODUCTS_PTG, formData).then(res=>{
        if(res.status==200 && res.data.code==200){
          var tips = ["上传成功"]
          message.success(tips.join(", "))
          handlePullData()
        }
        else{
          message.error("网络错误")
        }
      }).catch(ex=>{
        message.error(ex)
      })
      console.log(file)
    }
    const beforeUpload = (file) => {
      if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
        message.error("必须是xlsx文件")
        return
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(config.APIS_URL.BATCH_MGR_PRODUCTS, formData).then(res=>{
        if(res.status==200 && res.data.code==200){
          var tips = ["上传成功"]
          if(res.data.duplicate>0){
            tips.push(`经线重复${res.data.duplicate}条数据`)
          }
          if(res.data.duplicate2>0){
            tips.push(`纬线重复${res.data.duplicate2}条数据`)
          }
          message.success(tips.join(", "))
          handlePullData()
        }
        else{
          message.error("网络错误")
        }
      }).catch(ex=>{
        message.error(ex)
      })
      console.log(file)
    }

    const beforeSourceUpload = (file) => {
      if(file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
        message.error("必须是xlsx文件")
        return
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(config.APIS_URL.batch_TEST, formData).then(res=>{
        if(res.status==200 && res.data.code==200){
          var tips = ["上传成功"]
          message.success(tips.join(", "))
          handlePullSourceData()
        }
        else{
          message.error("网络错误")
        }
      }).catch(ex=>{
        message.error(ex)
      })
      console.log(file)
    }

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>原料管理</div>
                    <div style={{display:'flex'}}>
                      <Button onClick={()=>handleClear()} danger type='primary' style={{marginRight: '20px'}}>清空数据</Button>
                      <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                        beforeUpload={beforePtgUpload}>
                        <Button style={{marginRight: '20px'}} type="primary">更新原料配比表</Button>
                      </Upload>
                      <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                        beforeUpload={beforeUpload}>
                        <Button style={{marginRight: '20px'}} type="primary">导入Excel</Button>
                      </Upload>
                      <Button onClick={()=>{setOpen(true);}} type="primary">添加一行</Button>
                    </div>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={productData} 
                    columns={columns} />
            </div>
            <div style={{display:'flex'}}>
                      <Button onClick={()=>handleSourceClear()} danger type='primary' style={{marginRight: '20px'}}>清空数据</Button>
                      
                      <Upload maxCount={1} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                        beforeUpload={beforeSourceUpload}>
                        <Button style={{marginRight: '20px'}} type="primary">导入Excel</Button>
                      </Upload>
                    </div>
            <ArgsMgr sourceData={sourceData}></ArgsMgr>
            <Modal
              title="添加原料"
              centered
              onCancel={()=>{setOpen(false); setTableKey(tableKey+1)}}
              onOk={()=>handleAddData()}
              open={open}
              width={1000}
            >
              <table className='manager' key={tableKey}>
                <tbody>
                  <tr>
                    <th>经纬线</th><th>原料名</th><th>产品名</th><th>原料成分</th><th>规格</th><th>价格</th>
                  </tr>
                  <tr>
                    <td><Select options={longlatiList} defaultValue={longlatiList[0].label} onChange={(value)=>setnewDataChange(value)}></Select></td>
                    <td><Input onChange={(value)=>setnewData(value, 'raw_name')}></Input></td>
                    <td><Input onChange={(value)=>setnewData(value, 'product_name')}></Input></td>
                    <td><Input onChange={(value)=>setnewData(value, 'product_ptg')}></Input></td>
                    <td><Input type='number' onChange={(value)=>setnewData(value, 'density')}></Input></td>
                    <td><Input type='number' onChange={(value)=>setnewData(value, 'price')}></Input></td>
                  </tr>
                </tbody>
              </table>
          </Modal>
        </div>
    );
}
 
export default ProductMgr;