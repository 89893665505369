import React, { useEffect, useState } from "react"
import {Input, Table, Modal, Button, Popconfirm, Select, message, Switch} from "antd"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import config from "../constants"
import axios from "axios"

export default () => {
    const [data, setData] = useState([])
    const [tableKey, setTableKey] = useState(1)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newData, setNewData] = useState({})
    const [status, setStatus] = useState(false)
    const columns = [
        {
          title: '账号',
          dataIndex: 'account',
          key: 'member1',
          render: (text, row) => (
            <Input
              value={text}
              onChange={e => handleSave({ ...row, account: e.target.value })}
            />
          ),
        },
        {
            title: '昵称',
            dataIndex: 'name',
            key: 'member2',
            render: (text, row) => (
              <Input
                value={text}
                onChange={e => handleSave({ ...row, name: e.target.value })}
              />
            ),
          },
        {
          title: '密码',
          dataIndex: 'password',
          key: 'pass2',
          render: (text, row) => (
            <Input
              value={text}
              onChange={e => handleSave({ ...row, password: e.target.value })}
            />
          ),
        },
        {
            title: '账号类型',
            dataIndex: 'type',
            key: 'type2',
            render: (text, row) => (
              <Select options={userType}
                value={userType.find(f=>f.value==text)?.label}
                onChange={value => handleSave({ ...row, type: value })}
              />
            ),
          },
          {
            title: '邀请码',
            dataIndex: 'invitation_code',
            key: 'invitation_code',
            render: (text, row) => (
              <div>{text}</div>
            ),
          },
          {
            title: '登录次数',
            dataIndex: 'login_count',
            key: 'login_count',
            render: (text, row) => (
              <div>{text}</div>
            ),
          },
          {
            title: '最近登录',
            dataIndex: 'latest_login',
            key: 'latest_login',
            render: (text, row) => (
              <div>{text}</div>
            ),
          },
        {
          title: '操作',
          dataIndex: 'operation2',
          width: 120,
          key: 'operation2',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => {handleUpdate(row.id)}}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
              <Popconfirm title="确定删除吗?" onConfirm={() => {handleDelete(row.id)}}>
                <a className='delete' title="删除">
                  <DeleteOutlined className='edit-icon' />
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];
      const handleSave = row => {
        const tempdata = [...data];
        const index = tempdata.findIndex(item => row.id === item.id);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(tempdata)
    };
      const handleDelete = key =>{
        const index = data.findIndex(item => item.id == key);
        if(index>-1){
          axios.delete(`${config.APIS_URL.DELETE_MGR_USER}/${key}`).then(res=>{
            if(res.data.code==200){
              message.success('删除成功')
              pullData()
            }
            else{
              message.error('删除失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    const userType = [
        {
          label: '普通会员',
          value: 0
        },
        {
            label: '高级会员',
            value: 90
        },
        {
            label: '超级会员',
            value: 100
        },
    ]
    useEffect(()=>{
        pullData()
    }, [])
    const pullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.FETCH_USER_LIST).then(res=>{
            if(res.data.code==200){
                setData(res.data.user_list)
                setLoading(false)
                setStatus(res.data.status)
            }
        })
    }
    const handleChange = (checked) => {
      setStatus(checked);
      axios.post(config.APIS_URL.GET_SYS_MEMBERSHIP_CONFIG).then(res=>{
        if(res.data.code==200){
            message.info(checked?"会员功能已开启":"会员功能已关闭")
        }
    })
      
      console.log("Switch toggled:", checked);
    };
    const handleUpdate = key => {
        const index = data.findIndex(item => item.id == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_USER, data[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              pullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    const handleCHangeNewData = (value, label) => {
        var temp = newData
        if(label=="account"){
            temp.account = value
        }
        else if(label=="name"){
            temp.name = value
        }
        else if(label=="password"){
            temp.password = value
        }
        else if(label=="type"){
            temp.type = userType.find(f=>f.label==value).value
        }
        setNewData(temp)
    }
    const handleSelectCHangeNewData = (value) =>{
        let temp = newData
        temp.type = value
        setNewData(temp)
    }
    const handleCreateNewAccount = () => {
        if(!("account" in newData) || newData.account.trim()=="" || 
            !("name" in newData ) || newData.name.trim() == "" || 
            !("password" in newData) || newData.password.trim() ==""){
                message.error("参数错误")
                return
            }
        if(!newData.type){
            newData.type=userType[0].value
        }
        
        axios.post(config.APIS_URL.CREATE_USER, newData).then(res=>{
            if(res.status == 200 && res.data.code==200){
                if(res.data.msg){
                    message.error("用户已存在")
                }
                else{
                    message.success("添加成功")
                }
                
                pullData()
                setTableKey(tableKey+1)
            }
            else{
                message.error("添加失败")
            }
            setOpen(false)
        })
    }
    return (
        <>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>会员管理</div>
                    <Button onClick={()=>{setOpen(true);}} type="primary">添加新会员</Button>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={data} 
                    columns={columns} />
            </div>
            <span>会员功能开关</span>
            <Switch checked={status} onChange={handleChange}></Switch>
            <Modal
              title="添加会员"
              centered
              onCancel={()=>{setOpen(false); setTableKey(tableKey+1)}}
              onOk={()=>{handleCreateNewAccount()}}
              open={open}
              width={1000}
            >
              <table className='manager' key={tableKey}>
                <tbody>
                  <tr>
                    <th>账号</th><th>昵称</th><th>密码</th><th>账号类型</th>
                  </tr>
                  <tr>
                    <td><Input onChange={(e)=>{handleCHangeNewData(e.target.value, "account")}}></Input></td>
                    <td><Input onChange={(e)=>{handleCHangeNewData(e.target.value, "name")}}></Input></td>
                    <td><Input onChange={(e)=>{handleCHangeNewData(e.target.value, "password")}}></Input></td>
                    <td><Select options={userType} defaultValue={userType[0].label} onChange={(value)=>{handleSelectCHangeNewData(value)}}></Select></td>
                  </tr>
                </tbody>
              </table>
          </Modal>
        </>
    )
}