import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import zhTranslation from "./locales/zh.json";

localStorage.setItem("lang", "en")

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      zh: {
        translation: zhTranslation
      }
    },
    lng: localStorage.getItem("lang").toLocaleLowerCase(), // 默认语言
    fallbackLng: localStorage.getItem("lang").toLocaleLowerCase(), // 当前语言的翻译缺失时回退到该语言
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
