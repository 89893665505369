import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import {DownOutlined} from "@ant-design/icons"
import { Table, message, Spin, Tree } from 'antd';
import "./cargo.less"
 
function ChildManager(){
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [bigloading, setBigLoading] = useState(false)

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_MGR_CHILD_LIST).then(res=>{
        if(res.data.code==200){
            let temp = res.data.list
            // temp.map((m,i)=>m.key=m.id)
            setDataList(temp)
            console.log(temp)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    return (
        <div className='containter-box-1'>
            <Spin spinning={bigloading} size='large' tip="上传中，请不要关闭或刷新页面">
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>代理下级用户管理</div>
                </div>
                <Tree treeData={dataList} blockNode showLine switcherIcon={<DownOutlined />} />
            </Spin>
        </div>
    );
}
 
export default ChildManager;