import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "./constants.js"
import { useTranslation } from "react-i18next";
import {DownOutlined} from "@ant-design/icons"
import UserInstruction from "./userInstructin.js";
import i18n from "./i18n.js";
import {Button, Select, Image, Input, Spin, message, Modal, Menu, Dropdown, Form, Layout} from "antd"
import './userform.css'

function UserForm() {
    const { t } = useTranslation();
    const { Option } = Select;
    const [lang, setLang] = useState(localStorage.getItem("lang").toUpperCase())
    const [defaultCurrency, setDefaultCurrency] = useState('')
    const [showInstruction, setShowInstruction] = useState(false)
    const [adsList, setADSList] = useState([])
    const [showResult, setShowResult] = useState(false)
    const [statusConfig, setStatusConfig] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [latestUpdateDate, setLatestUpdateDate] = useState(null)
    const [secondDensity, setSecondDensity] = useState('')
    const [secondSpe,setSecondSpe] = useState('')
    const [secondDoor, setSecondDoor] = useState('')
    const [secondWeight, setSecondWeight] = useState('')
    const [rate, setRate] = useState(1)
    const [totalVisitors, setTotalVisitorsCount] = useState(0)
    const [totalTVisitors, setTotalTVisitorsCount] = useState(0)
    const [rateList, setRateList] = useState([])
    const [rawList, setRawList] = useState([])
    const [doorList, setDoorList] = useState([])//门幅
    const [data, setData] = useState([])
    const [ingredient, setIngredient] = useState(null) //成分表
    const [userInfo, setUerInfo] = useState({})
    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState(null)
    const [nickName, setNickName] = useState(null)
    const [currentLevel, setCurrentLevel] = useState(null)
    const [currentJac, setCurrentJac] = useState(t("largeJac"))
    const [jacList, setJacList] = useState(null)
    const [datadsource, setDatadSource] = useState([
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("simple"), rate:0.12, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("difficult"), rate:0.14, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("complex"), rate:0.16, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("jSimple"), rate:0.19, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("jDiffcult"), rate:0.2, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}, 
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("jComplex"), rate:0.22, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("jpComplex"), rate:0.05, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]},
        {door: '', yarn: '', circle: '', circledata:'', density:0, totalDensity:0, perweight:'', product_ptg: {str:'', info: []}, perprice: 0, difficuty: t("jpxComplex"), rate:0.035, price:'', raw_name:'', raw_price:'', fob_price:'', yarnList:[]}])
    
    const DropdownMenu = (
        <Menu>
            <Menu.Item key="1-1" onClick={()=>{setOpen(true)}}>{t("change password")}</Menu.Item>
            <Menu.Item key="1-2"><a onClick={()=>{signout()}}>{t("sign out")}</a></Menu.Item>
        </Menu>
    )
    const handleVisit = ()=>{
        axios.post(config.APIS_URL.INCREASE_VISTORS).then(res=>{
            console.log("访问+1")
            localStorage.setItem("lastVisit", new Date())
        })
    }
    const pullDescContent = () => {
        axios.post(`${config.APIS_URL.GET_MGR_INSTRUCTION}1/${lang.toLowerCase()}`).then(res=>{
            if(res.status==200 && res.data.code==200){
                setShowInstruction(res.data.display)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const fetchUserData = () => {
        var token = Cookies.get("fabric")
        if(token){
            axios.get(config.APIS_URL.GET_USER_INFO,
                {headers: {Authorization:`Bearer ${token}`}}).then(res=>{
                if(res.status==200 && res.data.code==200){
                    setUerInfo(res.data)
                }
                else{
                    message.error(t("auth msg"))
                    Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
                }
            }).catch(ex=>{})
        }
    }
    useEffect(()=>{
        fetchUserData()
        //i18n.changeLanguage("zh")
        const temFun = async ()=>{
            let lastVisit = localStorage.getItem("lastVisit")
            if(lastVisit){
                lastVisit = new Date(lastVisit)
                lastVisit.setHours(lastVisit.getHours() + 1)
            }
            if(!lastVisit || lastVisit<new Date()){
                handleVisit()
            }
            axios.get(config.APIS_URL.USER_GET_PRODUCT).then(res=>{
                if(res.data.code==200){
                    console.log(res.data)
                    handleSetRawList(res.data, "largeJac")
                    
                    setStatusConfig(Boolean(res.data.status))
                    setData(res.data.product_list)
                    setDoorList(res.data.door_list) 
                    setRateList(res.data.rate_list)
                    setLatestUpdateDate(res.data.latestDate)
                    setRate(res.data.rate_list[0].rate)
                    setDefaultCurrency(res.data.rate_list[0].value)
                    //rate = res.data.rate_list[0].rate
                }
                else{
                    message.error("network error")
                }
                console.log(res)
            }).catch(ex=>{
                message.error("network error")
                console.log(ex)
            })
        }
        document.title = t("mainTitle")
        temFun()
        pullVistors()
        pullDescContent()
    },[])
    const handleClickADS = link => {
        if(!link){
            return
        }
        window.open(link)
    }
    const pullADSData = () => {
        axios.post(config.APIS_URL.GET_MGR_ADS).then(res=>{
            if(res.status==200 && res.data.code==200){
                setADSList(res.data.list)
            }
            else{
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(message)
        })
    }
    const getJacTypeField = (value) => {
        return value == t('largeJac')?'largeJac':(value==t('smallJac')?'smallJac':'noJac')
    }
    
    const handleSetRawList = (source, sourceType) => {
        let raw = []
        let noJacLow = []
        let noJacHeigh = []
        source.product_list.filter(f=>f.jac_type==sourceType).map(item=>{
            if(raw.findIndex(f=>f.value==item.raw_name)<0){
                raw.push({value: item.raw_name, lable: item.raw_name})
            }
        })
        setRawList(raw)
        if(jacList==null){
            source.product_list.filter(f=>f.jac_type=="noJac").map(item=>{
                if(item.jac_type=='noJac'){
                    if(item.sub_type==0){
                        noJacLow.push(item.raw_name)
                    }
                    else{
                        noJacHeigh.push(item.raw_name)
                    }
                }
            })
            let temp_res = source.jacquard_data
            temp_res["noJacHeigh"] = [... new Set(noJacHeigh)]
            temp_res["noJacLow"] = [... new Set(noJacLow)]
            setJacList(temp_res)
        }
    }
    
    const pullVistors = () => {
        var total = 0
        var totalCount = 0
        axios.get(config.APIS_URL.GET_VISTORS_URL).then(res=>{
            if(res.status==200 && res.data.code==200){
                console.log(res)
                total = total + Math.round(res.data.count)
                totalCount = totalCount +  Math.round(res.data.total)
                var pre_cnt = res.data.pre_count
                var timerange = res.data.time_range

                var percentage = 0;
                timerange.map(item=>{
                    var timestart = new Date()
                    timestart = timestart.setHours(item.start.split(":")[0], item.start.split(":")[1], item.start.split(":")[2])

                    var current = new Date()
                    if(current.getTime()>=timestart){
                        percentage += item.pct
                    }
                })
                total += Math.round(pre_cnt*percentage)
                totalCount += Math.round(pre_cnt*percentage)
                setTotalVisitorsCount(total)
                setTotalTVisitorsCount(totalCount)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const DoorChange = (value, index) => {
        var temp = [... datadsource]
        temp.map((item,index)=>{
            item.door = value
        })
        setDatadSource(temp)
    }
    // const LatitudeChange = (item, index) => {
    //     datadsource[index].latitude = item
    // }
    const YarnChange = (item, index) => {
        let tempdata = [... datadsource]
        tempdata[index].yarn = item
        var pct_info = data.find(f=>f.raw_name==tempdata[index].raw_name&&f.value==item)?.product_ptg
        var pct_list = pct_info?pct_info.split('/'):[]
        tempdata[index].product_ptg.str = pct_info
        let tempPctInfo = []
        pct_list.map(m=>{
            var pct_key = m.split("%")[1]
            tempPctInfo.push({key: pct_key, pct: parseFloat(m.split("%")[0])*0.01})
        })
        tempdata[index].product_ptg.info = tempPctInfo
        setDatadSource(tempdata)
    }
    const RawChange = (item, index) => {
        if(item=="Clear Row"){
            handleClearRowData(index)
            return
        }
        var longl = index<=2?0:1
        var tempdata = data.filter(f=>f.raw_name==item && f.longLati==longl)
        if(longl==0){
            tempdata = tempdata.filter(f=>f.jac_type==getJacTypeField(currentJac))
        }
        var source = [... datadsource]
        source[index].yarn = ""
        source[index].raw_name = item
        source[index].yarnList = tempdata
        setDatadSource(source)
    }
    const handleClearRowData = index => {
        var source = [... datadsource]
        source[index].circle=''
        source[index].circledata=''
        source[index].yarn=''
        source[index].raw_name=''
        source[index].density = 0
        source[index].perweight = ''
        source[index]. perprice = 0
        setDatadSource(source)
        message.info("Cleared")
    }
    const CircleChange = (e, index) => {
        let tempdata = datadsource
        tempdata[index].circle = e.target.value
        setDatadSource(tempdata)
    }
    const CircleDataChange = (e, index) => {
        let tempdata = datadsource
        tempdata[index].circledata = e.target.value
        setDatadSource(tempdata)
    }
    const jacChange = value => {
        setCurrentJac(value)
        var source = [... datadsource]
        if(value==t('noJac')){
            for(let i=1; i<3;i++){
                source[i].circle=''
                source[i].circledata=''
                source[i].yarn=''
                source[i].raw_name=''
                source[i].density = 0
                source[i].perweight = ''
                source[i]. perprice = 0
                source[i].price=0
                source[i].fob_price=0
                source[i].raw_price=0
            }
        }
        setCurrentLevel(null)
        setShowResult(false)
        setDatadSource(source)
        handleSetRawList({"product_list": data}, getJacTypeField(value))
        //GetResult(source, value)
    }
    const rateChange = (value) => {
        var tmp = rateList.find(f=>f.value==value)
        var contryrate = tmp.rate
        var contryCurrency = tmp.value
        setDefaultCurrency(contryCurrency)
        setRate(contryrate)
    }
    const ChangeLang = () => {
        let nextLang = ""
        if(lang.toUpperCase()=="EN"){
            nextLang = "ZH"
        }
        else{
            nextLang = "EN"
        }
        let tempdata = [... datadsource]
        
        setLang(nextLang)
        //setShowDescTextList([])
        i18n.changeLanguage(nextLang.toLowerCase())
        //localStorage.setItem("lang", nextLang.toLowerCase()) //保存默认语言选择
        tempdata[0].difficuty = t("simple")
        tempdata[1].difficuty = t("difficult")
        tempdata[2].difficuty = t("complex")
        tempdata[3].difficuty = t("jSimple")
        tempdata[4].difficuty = t("jDiffcult")
        tempdata[5].difficuty = t("jComplex")

        let f_field = ''
        if(currentJac=="大提花"||currentJac=="Large Jacquard"){
            f_field="largeJac"
        }
        else if(currentJac=="小提花"||currentJac=="Small Jacquard"){
            f_field="smallJac"
        }
        else{
            f_field="noJac"
        }
        setCurrentJac(t(f_field))
        setDatadSource(tempdata)
    }
    const GetResult = (tempsource, jacType) => {
        // let auth = userInfo&&userInfo.code==200
        // if(!auth){
        //     message.error(t("loginTips"))
        //     return
        // }
        console.log(datadsource)
        tempsource = tempsource?tempsource:[... datadsource]
        let filledData = tempsource.slice(0, 3).filter(f=>f.circle!=''&&f.door!=''&&f.circledata!=''&&f.raw_name!=''&&f.yarn!='')
        var length = filledData
        if(length==undefined || length<=0){
            message.warning(t("empty tips"))
            return
        }
        let tempType = jacType?jacType:currentJac
        if(!filledData.map(m=>m.raw_name.toLowerCase()).every(e=>data.filter(f=>f.jac_type==getJacTypeField(tempType)).map(mp=>mp.raw_name.toLowerCase()).includes(e))){
            message.warning("Raw Material Composition Error")
            return
        }
        setShowLoading(true)
        axios.post(config.APIS_URL.GET_CACULATOR_RESULT,{"data_source":tempsource,"rate":rate,"jac_type": getJacTypeField(currentJac)}).then(res=>{
            if(res&&res.data.code==200){
                setDatadSource(res.data.data_source)
                setSecondSpe(res.data.secondSpe)
                setSecondDensity(res.data.totalden)
                setSecondDoor(tempsource[0].door)
                setSecondWeight(res.data.secondWeight)
                setIngredient(res.data.ingredient)
                setCurrentLevel(res.data.level)
            }
        }).catch(ex=>{
            console.log(ex)
        }).finally(()=>{
            setShowLoading(false)
            setShowResult(true)
        })
    }
    
    const handleUpdatePasswd = () => {
        if((password == null || password.toString().trim()=="") && (nickName == null || nickName.toString().trim()=="") ){
            message.warning(t("changeTip"))
            return
        }
        setShowLoading(true)
        var parm = {}
        if(password){
            parm.password = password
        }
        if(nickName){
            parm.name = nickName
        }
        axios.post(config.APIS_URL.UPDATE_USER_INFO, parm, 
            {headers: {Authorization: `Bearer ${Cookies.get("fabric")}`}}).then(res=>{
            if(res.data.code==200){
                fetchUserData()
                message.success(t("successTip"))
            }
            else{
                message.success(t("failTip"))
            }
            setShowLoading(false)
            setOpen(false)
        }).catch(ex=>{
            message.error(ex.response.data.detail)
            setShowLoading(false)
            setOpen(false)
            console.log(ex)
            if(ex.response.status==403){
                message.error(t("auth msg"))
                Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
            }
        })
    }

    const signout = () =>{
        Modal.confirm({
            "content": t("exitTip"),
            onOk: ()=>{
                Cookies.remove("fabric", {domain: window.location.hostname.replace(/th|tp|www/,"")})
                window.location.reload()
                setUerInfo({})
            }
        })
    }

    return (
        <Layout style={{minHeight:'100vh', background:'#fff'}}>
            <Layout.Content>
            <div className="containter-box">
            <div style={{marginLeft: '10px',marginTop: '5px' , fontSize: '16px', color: 'rgba(0,0,0,0.5)', display:'flex', justifyContent: 'space-between'}}>
                <div>
                    <div>
                        {t("visitors")}
                        <span style={{marginLeft: '10px'}}>{totalVisitors}</span>
                    </div>
                    <div>
                        {t("total visitors")}
                        <span style={{marginLeft: '10px'}}>{totalTVisitors}</span>
                    </div>
                </div>
                <a onClick={()=>ChangeLang()} type="link" style={{float: "right", color: '#1677ff', cursor: 'pointer', float: 'right'}}>{lang}</a>
            </div>
          <Spin spinning={showLoading} tip="loading..." size="lager">
            <div style={{display:'flex'}}>
                <div className="header">{t("mainTitle")}</div>
                {
                    userInfo&&userInfo.code==200?
                    <Dropdown className="login-btn" trigger={["click"]} overlay={DropdownMenu} style={{minWidth: '80px'}}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        Hi, {userInfo.name} <DownOutlined />
                        </a>
                    </Dropdown>
                    :
                    <a href={`${config.LoginURL}?resource=${encodeURIComponent(window.location.href)}`} className="login-btn" style={{minWidth: '40px'}}>{t("sign in")}</a>
                }
            </div>
            {/* <div className="searchBoxContainer">
                <div className="searchBox">
                    {fileToUpload?fileToUpload.name:t("search tips")}
                </div>
                <Upload className="hello" accept="image/png, image/jpeg, image/jpg" showUploadList={false} maxCount={1} beforeUpload={(file)=>handleBeforeUpload(file)} onRemove={()=>{setFileToUpload(null)}}>
                    <Button style={{height: '38px'}} icon={<SearchOutlined />}>{t?t("search Img"):null}</Button>
                </Upload>
            </div> */}
            <div style={{display:"flex", justifyContent: 'space-between', marginLeft: '10px'}}>
                <div>{/*<QuestionOutlined style={{color:"#1677ff", marginRight: '5px'}} />*/}
                <a href={`#/UserInstruction?lang=${lang}`}>{t("instruction")}</a></div>
            {
                latestUpdateDate?(
                    <div className="latest-update">
                        <span>{t("latet update")}</span>
                        <span style={{marginLeft:'10px', color: 'red', marginRight: '5px'}}>{latestUpdateDate}</span>
                    </div>
                ):null
            }
            </div>
             <div className="overTable">
                <table>
                    <tbody>
                        <tr>
                            <th className="title">{t("door")}</th>
                            <th className="title">{t("longLati")}</th>
                            <th className="title">{t("rawComp")}</th>
                            <th className="title">{t("yarn")}</th>
                            <th className="title">{t("loop")}</th>
                            <th className="title">{t("loopData")}</th>
                        </tr>
                        {
                            datadsource.slice(0, 6).map((item, index)=>(
                                <tr key={index}>
                                    <td><Select options={doorList} onChange={(value)=>{DoorChange(value, index)}} value={item.door} 
                                        disabled={currentJac==t("noJac") && (index == 2 || index==1)}></Select></td>
                                    {/* <td><Select options={latitude} defaultValue={index>2?'纬线':'经线'} onChange={(value)=>LatitudeChange(value, index)}></Select></td> */}
                                    <td className="title" style={index>2?{color:'red'}:null}>{index>2?t("Latitude"):t("Longitude")}</td>
                                    {/* <td><Select options={[{value: 'Clear Row', lable: 'Clear Row'}].concat(rawList)} onChange={(value)=>RawChange(value, index)} value={item.raw_name} 
                                        disabled={currentJac==t("noJac") && (index == 2 || index==1)}></Select></td> */}
                                     <td>
                                        <Select onChange={(value)=>RawChange(value, index)} value={item.raw_name} 
                                            disabled={currentJac==t("noJac") && (index == 2 || index==1)}>
                                                {
                                                    [{value: 'Clear Row', lable: 'Clear Row'}].concat(rawList).map((mm,idc)=>(
                                                        <Option key={`lk${idc}`} value={mm.value} style={idc==0?{color:'red'}:null}>{mm.value}</Option>
                                                    ))
                                                }
                                            </Select>
                                     </td>
                                    <td><Select options={item.yarnList} onChange={(value)=>YarnChange(value, index)} value={item.yarn}
                                        disabled={currentJac==t("noJac") && (index == 2 || index==1)}></Select></td>
                                    <td><Input key={item.circle} defaultValue={item.circle} type="number" placeholder={t("inputHolder")} onChange={(e)=>CircleChange(e, index)}
                                        disabled={currentJac==t("noJac") && (index == 2 || index==1)}>{/*item.circle*/}</Input></td>
                                    <td><Input key={item.circledata} defaultValue={item.circledata} type="number" placeholder={t("inputHolder")} onChange={(e)=>CircleDataChange(e, index)}
                                        disabled={currentJac==t("noJac") && (index == 2 || index==1)}>{/*item.circledata*/}</Input></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
             </div>
             
             <div style={{display:'flex', flexDirection: 'row', margin: '30px', justifyContent:'center'}}>
                <Button type="primary" className="calcButton" size="40" onClick={()=>GetResult(null, null)}>{t("calcRes")}</Button>
                <Select key="currency" style={{width: '80px'}} options={rateList} value={rate==1?t("currency"):defaultCurrency} 
                    onChange={(value)=>rateChange(value)}></Select>
                <Select className="jacSelector" key="jacType" style={{width: '140px', marginLeft: "20px"}} options={[{"value": t("largeJac")}, {"value": t("smallJac")}, {"value": t("noJac")}]} value={currentJac} 
                    onChange={(value)=>jacChange(value)}></Select>
            </div>
            
             {
                showResult?(
                <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                    <div style={{textAlign:'left', width: '100%', padding: '15px 5px', color:'red'}}>{t("ingredient")}：<span style={{color: '#000'}}>{ingredient}</span></div>
                    <div className="overTable">
                        <table className="secondTable">
                            <tbody>
                                <tr><th>{t("specification")}</th><th>{t("density")}</th><th>{t("door")}</th><th>{t("secondWeight")}</th></tr>
                                <tr>
                                    <td>{secondSpe}</td>
                                    <td>{secondDensity}</td>
                                    <td>{secondDoor}</td>
                                    <td>{secondWeight}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="overTable">
                        <table style={{marginTop: '30px'}}>
                            <tbody>
                                <tr>
                                    <th className="result-title"style={{backgroundColor:'orange', color: '#fff'}}>{t("greyPrice")}</th>
                                    <th className="result-title"style={{backgroundColor:'red', color: '#fff'}}>{t("price")}</th>
                                    <th className="result-title"style={{backgroundColor:'grey', color: '#fff'}}>{t("fobPrice")}</th>
                                </tr>
                                {
                                    datadsource?datadsource.map((item, index)=>(
                                        currentLevel == index?(
                                        <tr key={`htb3${index}`}>
                                            <td>{!statusConfig || (statusConfig && userInfo&&userInfo.type>=0)?item.raw_price:t("memberTip1")}</td>
                                            <td>{lang.toLowerCase()=='en'?item.price_en:item.price}</td>
                                            <td>{lang.toLowerCase()=='en'?item.fob_price_en:item.fob_price}</td>
                                        </tr>):null
                                        
                                    )) : null
                                }
                            </tbody>
                        </table>
                        <table style={{marginTop: '30px'}}>
                            <tbody>
                                <tr>
                                    {/* <th className="result-title">{t("longLati")}</th> */}
                                    {userInfo&&userInfo.type>=100?<th className="result-title">{t("difficuty")}</th>:null}
                                    <th className="result-title">{t("yarn")}</th>
                                    <th className="result-title">{t("density")}</th>
                                    <th className="result-title">{t("perWeight")}</th>
                                    <th className="result-title">{t("perPrice")}</th>
                                    {userInfo&&userInfo.type>=100?<th className="result-title">{t("price")}</th>:null}
                                    {userInfo&&userInfo.type>=100?<th className="result-title">{t("greyPrice")}</th>:null}
                                    {userInfo&&userInfo.type>=100?<th className="result-title">{t("fobPrice")}</th>:null}
                                </tr>
                                {
                                    datadsource?datadsource.map((item, index)=>(
                                        <tr key={`htb${index}`} style={index==currentLevel&&userInfo&&userInfo.type>=100?{backgroundColor: 'brown', color: '#fff'}:null}>
                                            {/* <td className="result-title" style={index>2?{color:'red'}:null}>{index<=2?t("Longitude"):t("Latitude")}</td> */}
                                            {userInfo&&userInfo.type>=100?<td>{item.difficuty}</td>:null}
                                            <td>{item.yarn}</td>
                                            <td>{!statusConfig || (statusConfig&&userInfo&&userInfo.type>=90)?item.density:t("memberTip2")}</td>
                                            <td>{!statusConfig || (statusConfig&&userInfo&&userInfo.type>=90)?item.perweight:t("memberTip2")}</td>
                                            <td>{!statusConfig || (statusConfig&&userInfo&&userInfo.type>=90)?item.perprice:t("memberTip2")}</td>
                                            {userInfo&&userInfo.type>=100?<td>{item.price}</td>:null}
                                            {userInfo&&userInfo.type>=100?<td>{item.raw_price}</td>:null}
                                            {userInfo&&userInfo.type>=100?<td>{item.fob_price}</td>:null}
                                        </tr>
                                        
                                    )) : null
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='desc-box'>
                        {
                            lang.toLowerCase()=='en'?(<div className="desc">instruct: </div>):(<div className="desc">说明: </div>)
                        }
                        {
                            instruct.find(f=>f.lang==lang.toLowerCase()).desc.map((item, index)=>(
                                <div key={`d-${index}`} className="desc">{item}</div>
                            ))
                        }
                    </div> */}
                </div>):null
             }
        <div>
        {
            showInstruction?<UserInstruction lanaguage={lang} />:null
        }
        {/*adsList&&adsList.length>0?<div className="ads-title">{t("partner")}</div>:null*/}
        {/* <div className="ads-container">
            {
                adsList.map((item,index)=>(
                    <div key={`a-${index}`} onClick={()=>handleClickADS(item.link)} className='ads-container-box'>
                        <Image preview={false} style={{height: '60px'}} src={`https://images.weserv.nl/?url=${config.HOST}/${item.img_url}`}></Image>
                        <div title={lang.toLowerCase()=='zh'?item.txt:item.txtWithEN} className="ads-sub-title">
                            {lang.toLowerCase()=='zh'?item.txt:item.txtWithEN}
                        </div>
                    </div>
                ))
            }
        </div>  */}
        </div>
        <Modal title={t("change password")}
              centered
              onCancel={()=>{setOpen(false)}}
              onOk={()=>{handleUpdatePasswd()}}
              open={open}>
            <Form>
                <Form.Item label={t("account")}><Input disabled value={userInfo.account} /></Form.Item>
                <Form.Item label={t("nickName")}><Input onChange={(e)=>{setNickName(e.target.value)}} /></Form.Item>
                <Form.Item label={t("password")}><Input type="password" onChange={(e)=>{setPassword(e.target.value)}}></Input></Form.Item>
            </Form>
        </Modal>
        </Spin>
    </div>
            </Layout.Content>
            {
                lang=="ZH"?<Layout.Footer className="footer">Copyright© 2024 提花面料价格计算 <a href="http://beian.miit.gov.cn/">浙ICP备2024083871号</a></Layout.Footer>:null
            }
            
        </Layout>
    )
}

export default UserForm