import {Select, Input, Button, message, Modal, Switch} from "antd"
import { useRef, useEffect, useState } from "react"
import config from "../constants"
import axios from "axios"

export default ()=>{
    const {TextArea} = Input
    const textareaRef = useRef(null)
    const [latestDate, SetLatestDate] = useState(null)
    const lang = [{label: '中文',value: 0, label2: 'zh'}, {label: '英文', value: 1, label2: "en"}]
    const [selectLang, setSelectLang] = useState(null)
    const [desc, setDesc] = useState(null)
    const [id, setID] = useState(undefined)
    const [homeDisplay, setHomeDisplay] = useState(null)
    const handleSubmit = () => {
        let url = config.APIS_URL.UPDATE_MGR_INSTRUCTION
        Modal.confirm({
            title: "更新",
            content: "是否确定更新?",
            onOk: ()=>{
                let value = textareaRef.current.resizableTextArea.textArea.value
                var json = {
                    id: id,
                    description: value,
                    lang: selectLang,
                    type: 1
                }
                axios.post(url, json).then(res=>{
                    if(res.status==200 && res.data.code==200){
                        message.success("更新成功")
                        handlePullData()
                    }
                    else {
                        message.error("网络错误")
                    }
                }).catch(ex=>{
                    message.error(ex)
                })
            }
        })
    }
    const handlePullData = (currentLang) => {
        axios.post(`${config.APIS_URL.GET_MGR_INSTRUCTION}1/${currentLang}`).then(res=>{
            if(res.status==200 && res.data.code==200 && res.data.list.length>0){
                setID(res.data.list[0].id)
                setDesc(res.data.list[0].description)
                SetLatestDate(res.data.list[0].update_date)
                setHomeDisplay(res.data.display)
            }
            else if(res.status!=200 || res.data.code!=200) {
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const handleChangeDisplay = v => {
        axios.post(`${config.APIS_URL.UPDATE_MGR_INSTRUCTION_DISPLAY}/${!homeDisplay}`).then(res=>{
            setHomeDisplay(!homeDisplay)
        }).catch(ex=>{
            message.error("出错啦")
        })
    }
    const changeSelect = value => {
        resetContent()
        setSelectLang(value)
        handlePullData(lang[value].label2)
    }
    const resetContent = () =>{
        SetLatestDate(null)
        setDesc(null)
    }
    useEffect(()=>{
        handlePullData(lang[0].label2)
        setSelectLang(lang[0].value)
    }, [])
    return (
        <div style={{display:'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px'}}>上次更新时间</span>
                <div style={{width: '200px'}}>{latestDate}</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px'}}>选择语言</span>
                <Select style={{width: '200px'}} onChange={(value)=>changeSelect(value)} options={lang} defaultValue={lang[0].value} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px'}}>首页打开</span>
                <Switch checked={homeDisplay} style={{width: '50px'}} onChange={(v)=>{handleChangeDisplay(v)}} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px', marginBottom: '10px'}}>说明</span>
            </div>
            <TextArea key={"textarea"} ref={textareaRef} 
                cols={60} init value={desc} 
                onChange={e => setDesc(e.target.value)}
                style={{maxWidth: '750px', height: "calc(100vh - 350px)"}}></TextArea>
            <Button style={{width: '80px', marginTop: '20px'}} type="primary" onClick={()=>handleSubmit()}>提交</Button>


            <div style={{color: 'red'}}>
                <p style={{fontWeight: 'bold'}}>*注意</p>
                <p>对于链接，必须使用&lt;&gt;包裹起来</p>
                <p>对于视频类型链接，必须使用前缀<span style={{color:'#fff', background:'red'}}>video:&nbsp;</span>。请注意，这里的:是英文符号，后面有一个空格</p>
                <p>例：普通链接： &lt;https://baidu.com&gt;</p>
                <p>视频链接: &lt;video:  https://media.w3.org/2010/05/sintel/trailer.mp4&gt;</p>
            </div>
        </div>
        
    )
}