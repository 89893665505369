import {React, useState} from "react"
import {Modal} from "antd"

export default (props) => {
    const [part1] = useState(props.item.match(/\<(.*?)\>/)[0])
    const [part2] = useState(props.item.match(/\<(.*?)\>/)[1])

    const handleJumpSite = (e) => {
        const {type, url} = e.target.dataset
        if(type=="video"){
            Modal.confirm({
                content: <video controls autoPlay width={'100%'}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>,
                icon: null,
                mask: true,
                closable: true,
                maskClosable:true,
                footer: null,
                width: '720px',
                centered: true,
                className: "jump-site-modal",
                onCancel: ()=>{}
            })
        }
        else {
            window.open(url, "_blank")
        }
    }

    const handleGetVideoName = (value) => {
        return decodeURIComponent(value.replace(/video: /i, ""))
    }

    return (
        <div key={props.className} className={props.className}>
        {
            props.item.replace(part1, `;+${part2};+` ).split(";+").map((itm, idx)=>(
                <span key={`${itm}-${idx}`}>{itm != part2 ? itm : null}{itm==part2?<a onClick={(e)=>{handleJumpSite(e)}} target="_blank" 
                data-type={itm.toLowerCase().startsWith("video")?'video':'website'}
                data-url={itm.replace(/video: /i, "")}
            >{itm.toLowerCase().startsWith("video")?handleGetVideoName(itm).split("\/")[handleGetVideoName(itm).split("\/").length-1].replace(/\.mp4/i,""):itm}</a>:null}</span>))
        }
        </div>
    )
}