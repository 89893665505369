import React, { Component } from 'react'
import {Button} from 'antd'

export default class MButton extends Component {
  render() {
    return (
        <div>
            <Button style={{cursor:'pointer','marginRight':'20px'}} type={this.props.type} onClick={this.props.event}  >{this.props.value}</Button>
      </div>
    )
  }
}
