import React, { useEffect, useState } from 'react'
import MField from './components/MField'
import MButton from './components/MButton'
import axios from 'axios'
import config from './constants'
import Cookies from'js-cookie'
import {Spin, message} from "antd"
import ShowLinkDescription from "./components/showLinkDescription.js";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Login() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [showDescTextList, setShowDescTextList] = useState([])
    const [lang] = useState(localStorage.getItem("lang").toUpperCase())

    useEffect(()=>{
        pullDescContent(lang)
    }, [])

    const pullDescContent = (lanaguage) => {
        axios.post(`${config.APIS_URL.GET_MGR_INSTRUCTION}2/${lanaguage.toLowerCase()}`).then(res=>{
            if(res.status==200 && res.data.code==200 && res.data.list.length>0){
                console.log(res.data.list[0].description)
                let arr = res.data.list[0].description.split("\n")
                setShowDescTextList(arr)
            }
            else if(res.status!=200 || res.data.code!=200){
                message.error("network error")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }

    const handleLogin = ()=>{
        if(username.trim()=='' || username == undefined || password.trim()=='' || password == undefined){
            alert("请输出用户名和密码")
            return
        }
        setLoading(true)
        axios.post(config.APIS_URL.LOGIN_URL, {'name': username, 'password': password}).then(res=>{
            if(res.data.code==200){
                Cookies.set("fabric", res.data.token, {domain: window.location.hostname.replace(/th|tp|www/,"")})
                navigate('/UserForm')
            }
            else{
                alert("账号或密码错误")
            }
            setLoading(false)
        }).catch(ex=>{
            console.error(ex)
        })
    }
    const handleRegister = ()=>{
        if(username.trim()=='' || username == undefined || password.trim()=='' || password == undefined){
            alert("请输出用户名和密码")
            return
        }
        axios.post(config.APIS_URL.REGIST_URL, {'name': username, 'password': password}).then(res=>{
            if(res.data.code==200 && res.data.msg){
                alert(res.data.msg)
            }
            else if(res.data.code==200){
                alert("注册成功")
            }
            else{
                alert("注册失败")
            }
        }).catch(ex=>{
            console.error(ex)
        })
    }
    const handleUsernameChange = (value) => {
        setUsername(value);
      };
    
    const handlePasswordChange = (value) => {
        setPassword(value);
    };
    return (
        <div className='container-box'>
            <Spin spinning={loading} size='large'>
            <h2 style={{textAlign:'center'}}>登录</h2>
            <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
                <MField label="用户名" type="text" onChangeEvent={handleUsernameChange} value={username} ></MField>
                <MField label="密码" type="password" onChangeEvent={handlePasswordChange} value={password}></MField>
                <div style={{ display: 'flex', 'marginLeft': '110px', alignItems:'center' }}>
                    <MButton type="primary" value="登录" event={handleLogin} />
                    <a href="#/signup">立即注册</a>
                    {/* <MButton type="button" value="重置密码" event={handleRegister} /> */}
                </div>
                {/* <div>
                    <div style={{textAlign: 'center', marginTop: '50px'}}>使用说明</div>
                    <div className='no-circle'>1，本工具仅适用于计算提花面料的价格和克重。对于常规面料，仅可克重计算。</div>
                    <div className='no-circle'>2，门幅以英寸为单位，周期以厘米为单位。</div>
                    <div className='no-circle'>3，测量周期及周期内数据的方法，请参阅视频教程。<a href='https://v.douyin.com/iFu55gbX/'>https://v.douyin.com/iFu55gbX/</a></div>
                    <div className='no-circle'>4，随着市场上原料价格的变化，计算价格也会相应调整。</div>
                    <div className='no-circle'>5.如果不知道面料的难易程度请联系微信 8613656850833</div>
                    <div className='no-circle'>6.以上价格是120GMS以内，每增加15G增加0.2元/米</div>
                </div> */}
                <div className="containter-box">
            {/* <div className="header">{t("instruction")}</div> */}
            <div className="desc-box">
            {
                showDescTextList.map((item, index)=>(
                    index==0?
                    <div className="desc" style={{fontWeight: 600}}>{item}</div>:
                    ((item.indexOf("<")>=0 && item.indexOf(">")>=0 && (!/\s\s/.test(item)))?
                    <ShowLinkDescription key={`desc${index}`} item={item} className={"desc"} />:
                        (
                            /\s\s/.test(item) ?
                                (item.indexOf("<")>=0 && item.indexOf(">")>=0?<ShowLinkDescription key={`subdesc${index}`} item={item} className={"sub-desc"} />: <div className="sub-desc">{item}</div>):
                            <div className="desc">{item}</div>
                        )
                    )
                ))
            }
            </div>
            </div>
            </div>
            </Spin>
        </div>
    )
}
export default Login