import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Upload, Select } from 'antd';
 
function ArgsMgr({sourceData}){
    const [open, setOpen] = useState(false)
    const [productData, setProductData] = useState([])
    const [tableKey, setTableKey] = useState(0)
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const longlatiList = [{label: '经线', value:0}, {label: '纬线', value: 1}]

    const columns = [
      {
        title: '原料成分',
        dataIndex: 'raw_name_ptg',
        key: 'raw_name_ptg',
        defaultSortOrder: '',
        sorter: (a, b) => a.raw_name_ptg - b.raw_name_ptg,
        render: (text, row) => (
          <div>{text}</div>
        ),
      },
        {
          title: '变量1',
          dataIndex: 'arg1',
          key: 'arg1',
          render: (text, row) => (
            <div>{text}</div>
          ),
        },
        {
          title: '变量2',
          dataIndex: 'arg2',
          key: 'arg2',
          render: (text, row) => (
            <div>{text}</div>
            // <Input value={text}
            //   onChange={e => handleSave({ ...row, product_ptg: e.target.value })}
            // />
          ),
        },
        {
          title: '变量3',
          dataIndex: 'arg3',
          key: 'arg3',
          render: (text, row) => (
            <div>{text}</div>
          ),
        }
      ];

    useEffect(()=>{       
        
    }, [])

    const handleSave = row => {
        const tempdata = [...productData];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setProductData(tempdata)
    };
    const setnewDataChange = (value) => {
      var data = newData
      data.longLati = value
      setNewDataA(data)
    }
    const setnewData = (e, index)=>{
        var data = newData
        if(index=='raw_name'){
            data.raw_name = e.target.value
          }
          else if(index=='product_name'){
            data.product_name = e.target.value
          }
          else if(index=='density'){
            data.dentisty = e.target.value
          }
          else if(index=='price'){
            data.price = e.target.value
          }
          else if(index=="product_ptg"){
            data.product_ptg = e.target.value
          }
        setNewDataA(data)
    }

    return (
        <Table className='manager-table2' bordered loading={loading} dataSource={sourceData} 
                    columns={columns} />
    );
}
 
export default ArgsMgr;