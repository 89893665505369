import {Select, Input, Button, message, Modal} from "antd"
import { useRef, useEffect, useState } from "react"
import config from "../constants"
import axios from "axios"

export default ()=>{
    const {TextArea} = Input
    const textareaRef = useRef(null)
    const [latestDate, SetLatestDate] = useState(null)
    const lang = [{label: '首页',value: 0, label2: 'home'}, {label: '详情页', value: 1, label2: "detail"}]
    const [selectLang, setSelectLang] = useState(null)
    const [desc, setDesc] = useState(null)
    const [instructions, setInstructions] = useState([])
    const [id, setID] = useState(undefined)
    const handleSubmit = () => {
        let url = config.APIS_URL.USER_MGR_INSTRUCTION
        Modal.confirm({
            title: "更新",
            content: "是否确定更新?",
            onOk: ()=>{
                let value = textareaRef.current.resizableTextArea.textArea.value
                var json = {
                    id: id,
                    description: value,
                    label: selectLang,
                    action: 1
                }
                axios.post(url, json).then(res=>{
                    if(res.status==200 && res.data.code==200){
                        message.success("更新成功")
                        handlePullData(selectLang)
                    }
                    else {
                        message.error("网络错误")
                    }
                }).catch(ex=>{
                    message.error(ex)
                })
            }
        })
    }
    const handlePullData = (label) => {
        axios.post(`${config.APIS_URL.USER_MGR_INSTRUCTION}`, {"action": 4, "label": label}).then(res=>{
            if(res.status==200 && res.data.code==200 && res.data.data){
                setDesc(res.data.data.txt)
                setID(res.data.data.id)
                SetLatestDate(res.data.data.update_date)
            }
            else if(res.status!=200 || res.data.code!=200) {
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    const changeSelect = value => {
        resetContent()
        setSelectLang(lang[value].label2)
        handlePullData(lang[value].label2)
    }
    const resetContent = () =>{
        SetLatestDate(null)
        setDesc(null)
    }
    useEffect(()=>{
        setSelectLang(lang[0].label2)
        handlePullData(lang[0].label2)
        setSelectLang(lang[0].label2)
    }, [])
    return (
        <div style={{display:'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px'}}>上次更新时间</span>
                <div style={{width: '200px'}}>{latestDate}</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px'}}>选择页面</span>
                <Select style={{width: '200px'}} onChange={(value)=>changeSelect(value)} options={lang} defaultValue={lang[0].value} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
                <span style={{width: '120px', marginBottom: '10px'}}>说明</span>
            </div>
            <TextArea key={"textarea"} ref={textareaRef} 
                cols={60} init value={desc} 
                onChange={e => setDesc(e.target.value)}
                style={{maxWidth: '750px', height: "calc(100vh - 300px)"}}></TextArea>
            <Button style={{width: '80px', marginTop: '20px'}} type="primary" onClick={()=>handleSubmit()}>提交</Button>
        </div>
        
    )
}