import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message, Image, Upload, Checkbox, Spin } from 'antd';
import "./cargo.less"

function StyleManager(){
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [bigloading, setBigLoading] = useState(false)
    const columns2 = [
        {
            title: '序号',
            dataIndex: 'seq_no',
            key: 'seq_no',
            width: 150,
            render: (text, row) => (
              <Input title={text} onChange={e => handleDoorSave({ ...row, seq_no: e.target.value })} type='number' value={text}></Input>
            ),
          },
        {
          title: '花型',
          dataIndex: 'style',
          key: 'style-1',
          render: (text, row) => (
            <div title={text}>{text}</div>
          ),
        },
        {
          title: '操作',
          dataIndex: 'operation20',
          width:100,
          key: 'operation20',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title={"确定修改吗？"} onConfirm={() => handleDoowUpdate(row.id)}>
                <a className='edit' title="发货">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_MGR_STYLE).then(res=>{
        if(res.data.code==200){
            let temp = res.data.list
            temp.map((m,i)=>m.key = m.id)
            setDataList(temp)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoowUpdate = key => {
        const index = dataList.findIndex(item => item.id == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_STYLE, dataList[index]).then(res=>{
            if(res.data.code==200){
              message.success('状态修改成功')
              handlePullData()
            }
            else{
                message.error('状态修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    
    const handleDoorSave = row => {
        const tempdata = [...dataList];
        const index = tempdata.findIndex(item => row.id === item.id);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataList(tempdata)
    };

    const handleClearStyle = () => {
      //CLEAR_STYLE
      Modal.confirm({
        title:"删除",
        content: "是否删除所有？",
        onOk:()=>{
            axios.delete(`${config.APIS_URL.CLEAR_STYLE}`).then(res=>{
                if(res.data.code==200){
                    message.success('删除成功')
                    handlePullData()
                }
                else{
                  message.error('删除失败')
                }
              }).catch(ex=>{
                console.error(ex)
                message.error(ex.message)
              })
        }
      })
    }
    
    return (
        <div className='containter-box-1'>
            <Spin spinning={bigloading} size='large' tip="修改中...">
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>花型管理</div>
                    <div><Button type='primary' danger onClick={()=>{handleClearStyle()}}>删除所有</Button></div>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={dataList} 
                    columns={columns2} />
            </Spin>
        </div>
    )
}
export default StyleManager
