import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import "./manager.css"
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"
import { Table, Input, Button, Popconfirm, Modal, message } from 'antd';
 
function DoorManager(){
    const [open, setOpen] = useState(false)
    const [tableKey, setTableKey] = useState(0)
    const [doorData, setDoorData] = useState([])
    const [loading, setLoading] = useState(false)
    const [newData, setNewDataA] = useState({})
    const columns2 = [
        {
          title: '纬线门幅',
          dataIndex: 'door1',
          key: 'door1',
          render: (text, row) => (
            <Input
              value={text}
              type='number'
              onChange={e => handleDoorSave({ ...row, door1: e.target.value })}
            />
          ),
        },
        {
          title: '对应门幅',
          dataIndex: 'door2',
          key: 'door2',
          render: (text, row) => (
            <Input
              type="number"
              value={text}
              onChange={e => handleDoorSave({ ...row, door2: e.target.value })}
            />
          ),
        },
        {
          title: '操作',
          dataIndex: 'operation2',
          width: 120,
          key: 'operation2',
          render: (text, row) => (
            <div className='edit-box'>
              <Popconfirm title="确定更新?" onConfirm={() => handleDoowUpdate(row.key)}>
                <a className='edit' title="编辑">
                  <EditOutlined className='edit-icon'></EditOutlined>
                </a>
              </Popconfirm>
              <Popconfirm title="确定删除吗?" onConfirm={() => handleDoorDelete(row.key)}>
                <a className='delete' title="删除">
                  <DeleteOutlined className='edit-icon' />
                </a>
              </Popconfirm>
            </div>
            
          ),
        }
      ];

    useEffect(()=>{       
        handlePullData()
    }, [])
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_MGR_PRODUCT).then(res=>{
        if(res.data.code==200){
            setDoorData(res.data.door_list)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const handleDoowUpdate = key => {
        const index = doorData.findIndex(item => item.key == key);
        if(index>-1){
          axios.post(config.APIS_URL.UPDATE_MGR_DOOR, doorData[index]).then(res=>{
            if(res.data.code==200){
              message.success('修改成功')
              handlePullData()
            }
            else{
                message.error('修改失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }

    const handleDoorDelete = key =>{
        const index = doorData.findIndex(item => item.key == key);
        if(index>-1){
          axios.delete(`${config.APIS_URL.DEL_MGR_DOOR}/${key}`).then(res=>{
            if(res.data.code==200){
              message.success('删除成功')
              handlePullData()
            }
            else{
              message.error('删除失败')
            }
          }).catch(ex=>{
            console.error(ex)
          })
        }
    }
    
    const handleDoorAddData = ()=>{
        if(newData.door1==undefined || newData.door2==undefined){
          message.warning('请完全输入数据')
          return
        }
        axios.post(config.APIS_URL.INSERT_MGR_DOOR, newData).then(res=>{
          console.log(res)
          if(res.data.code==200){
            message.success("添加成功")
            handlePullData()
            setOpen(false)
          }
          else{
            message.error('添加失败')
          }
          setTableKey(tableKey+1)
        }).catch(ex=>{
            setTableKey(tableKey+1)
            console.error(ex)
        })
    }
    
    const handleDoorSave = row => {
        const tempdata = [...doorData];
        const index = tempdata.findIndex(item => row.key === item.key);
        const item = tempdata[index];
        tempdata.splice(index, 1, {
          ...item,
          ...row,
        });
        setDoorData(tempdata)
    };
    
    const setnewData = (e, index)=>{
        var data = newData
        if(index=='door_value'){
            data.door1 = e.target.value
        }
        else if(index=='map_value'){
            data.door2 = e.target.value
        }
        setNewDataA(data)
    }

    return (
        <div className='containter-box-1'>
            <div>
                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className='sub-title'>门幅管理</div>
                    <Button onClick={()=>{setOpen(true);}} type="primary">添加一行</Button>
                </div>
                <Table className='manager-table' bordered loading={loading} dataSource={doorData} 
                    columns={columns2} />
            </div>
        
            <Modal
              title="添加门幅"
              centered
              onCancel={()=>{setOpen(false); setTableKey(tableKey+1)}}
              onOk={()=>handleDoorAddData()}
              open={open}
              width={1000}
            >
              <table className='manager' key={tableKey}>
                <tbody>
                  <tr>
                    <th>纬线门幅</th><th>对应门幅</th>
                  </tr>
                  <tr>
                    <td><Input type='number' onChange={(value)=>setnewData(value, 'door_value')}></Input></td>
                    <td><Input type='number' onChange={(value)=>setnewData(value, 'map_value')}></Input></td>
                  </tr>
                </tbody>
              </table>
          </Modal>
        </div>
    );
}
 
export default DoorManager;