var Host = "https://api.qifu2023.com"  //国外的服务器
const IMG_HOST = "http://156.247.10.146:8080/images"
const LoginURL = "https://tp.qifu2023.com/#/login"
// const Host = "https://api.qifu2023.com.cn" //国内的服务器
// const IMG_HOST = "http://43.139.147.103:8090/"
// const LoginURL = "https://th.qifu2023.com.cn/#/login"

if(process.env.NODE_ENV=="development"){
    Host = ""
}

const API_URLS = {
    INCREASE_VISTORS: `${Host}/increase_visitors`,
    USER_GET_PRODUCT: `${Host}/get_product`,
    LOGIN_URL: `${Host}/login`,
    REGIST_URL: `${Host}/register`,
    LOGIN_MGR_URL: `${Host}/adminlogin`,
    GET_VISTORS_URL: `${Host}/get_visitors`,
    UPDATE_MGR_RATE: `${Host}/update__mgr_rate`,
    UPDATE_MGR_PRODUCT: `${Host}/update__mgr_product`,
    DEL_MGR_RATE: `${Host}/del__mgr_rate`,
    DEL_MGR_PRODUCT: `${Host}/del__mgr_product`,
    DEL_MGR_DOOR: `${Host}/del__mgr_doors`,
    GET_MGR_PRODUCT: `${Host}/get__mgr_product`,
    UPDATE_MGR_DOOR: `${Host}/update__mgr_door`,
    INSERT_MGR_PRODUCT: `${Host}/add_products`,
    INSERT_MGR_RATE: `${Host}/add_rates`,
    INSERT_MGR_DOOR: `${Host}/add_doors`,
    UPDATE_MGR_PICS: `${Host}/update_pics_info`,
    DEL_MGR_PICS: `${Host}/del__mgr_pics_info`,
    GET__MGR__PICS_INFO: `${Host}/get_pics_info`,
    INSERT__MGR__PICS_INFO: `${Host}/add__mgr_pics_info`,
    MATCH__PICS_INFO: `${Host}/match_pics`,
    GET_MGR_INSTRUCTION: `${Host}/get_mgr_instruction/`,
    UPDATE_MGR_INSTRUCTION: `${Host}/update_mgr_instruction`,
    BATCH_MGR_PRODUCTS: `${Host}/batch_mgr_product`,
    GET_MGR_PRES: `${Host}/get_preVisi`,
    UPDATE_MGR_PRES: `${Host}/update_preVisi`,
    GET_MGR_ADS: `${Host}/get_mgr_ads`,
    DEL_MGR_ADS: `${Host}/delete_mgr_ads`,
    UPDATE_MGR_ADS: `${Host}/update_mgr_ads`,
    ADD_MGR_ADS: `${Host}/add_mgr_ads`,
    GET_USER_INFO: `${Host}/get_user_info`,
    UPDATE_USER_INFO: `${Host}/update_user_info`,
    FETCH_USER_LIST:`${Host}/ge_user_list`,
    CREATE_USER:`${Host}/register`,
    DELETE_MGR_USER:`${Host}/delete_mgr_user`,
    UPDATE_MGR_USER: `${Host}/update_mgr_user`,
    UPDATE_MGR_INSTRUCTION_DISPLAY: `${Host}/update_mgr_instruction_display`,
    GET_SYS_COE_CONFIG: `${Host}/get__form_coefficient`,
    UPDATE_SYS_COE_CONFIG: `${Host}/update__form_coefficient`,
    BATCH_MGR_PRODUCTS_PTG: `${Host}/batch_mgr_product_ptg`,
    FETCH_PRODUCT_INFO: `${Host}/get_product_by_type/`,
    FETCH__MGR_PTG_LIST: `${Host}/get_mgr_ptg_list`,
    FETCH_MGR_SCORE_LIST: `${Host}/get__mgr_score_list`,
    UPDAE_MGR_SCORE_INFO: `${Host}/set__mgr_score_list`,
    BATCH_MGR_JAC_LIST: `${Host}/upload_mgr_jacquard`,
    CLEAR_MGR_JAC_LIST: `${Host}/clear_mgr_jacquard`,
    GET_SYS_MEMBERSHIP_CONFIG: `${Host}/update_mgr_switch`,
    GIGN_UP_URL: `${Host}/signup`,
    GET_CACULATOR_RESULT: `${Host}/get_result`,
    GET_TEST: `${Host}/get_mgr_ag`,
    DELETE_TEST: `${Host}/delete_mgr_ag`,
    batch_TEST: `${Host}/batch_mgr_ag`,

    /**商城后台接口 */
    GET_CARGO_INFO: `${Host}/api/mgr/get_cargo_list`,
    REMOVE_CARGO_IDS: `${Host}/api/mgr/remove_cargo_ids`,
    UPLOAD_MGR_EXCEL: `${Host}/api/mgr/upload_cargo_list`,
    UPLOAD_MGR_IMAGE: `${Host}/api/mgr/upload_cargo_image`,
    GET_MGR_ORDER_LIST: `${Host}/api/mgr/get_cargo_orders`,
    UPDATE_MGR_ORDER_STATUS: `${Host}/api/mgr/update_cargo_order_status`,
    UPDATE_MGR_CARGO: `${Host}/api/mgr/update_cargo_list`,
    GET_MGR_STYLE: `${Host}/api/mgr/get_cargo_style`,
    UPDATE_MGR_STYLE: `${Host}/api/mgr/update_cargo_style`,
    GET_MGR_CHILD_LIST: `${Host}/get_child_list`,
    USER_MGR_INSTRUCTION: `${Host}/api/mgr/user_mgr_instruction`,
    USER_MGR_PREVISIT: `${Host}/api/mgr/user_mgr_pre_visit`,
    CLEAR_CARGO: `${Host}/api/mgr/remove_cargo_list`,
    CLEAR_STYLE: `${Host}/api/mgr/remove_style_list`,
    GET_FAKE_SOLD: `${Host}/api/mgr/get_fake_sold`,
    CLEAR_FAKE_SOLD: `${Host}/api/mgr/clear_fake_sold`,
    UPLOAD_FAKE_SOLD: `${Host}/api/mgr/upload_fake_sold`,

}

const difficulty = {
    0: '一级',
    1: '二级',
    2: '三级',
    3: '四级',
    4: '五级',
    5: '六级',
    6: '七级',
    7: '八级'
}
const difficultyEn = {
    0: 'simple',
    1: 'difficult',
    2: 'complex',
    3: 'jSimple',
    4: 'jDiffcult',
    5: 'jComplex',
    6: 'jpComplex',
    7: 'jpxComplex'
}

export default {APIS_URL: API_URLS, DIFFICULTY: difficulty, DIFFICULTY_EN: difficultyEn, HOST: IMG_HOST, LoginURL:LoginURL}