import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import { message, Input, Button } from 'antd';
 
function VisitorsManager(){
    const [visitorsCount, setVisitorsCount] = useState(0)
    const [totalVisitors, setTotalVisitorsCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [preVisitors, setPreVisitor] = useState(null)
    const [latestUPdate, setLatestUpdate] = useState(null)

    useEffect(()=>{       
        handlePullData()

        handlePullPreData()
    }, [])

    const handlePullPreData = () =>{
        axios.post(config.APIS_URL.GET_MGR_PRES).then(res=>{
            if(res.status==200 && res.data.code==200){
                let tmp = res.data.data
                setPreVisitor(tmp.length>0?tmp[0].cnt:null)
                setLatestUpdate(tmp.length>0?tmp[0].update_date:null)
            }
            else{
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }
    
    const handlePullData = () => {
        setLoading(true)
        axios.get(config.APIS_URL.GET_VISTORS_URL).then(res=>{
        if(res.data.code==200){
            setVisitorsCount(res.data.count)
            setTotalVisitorsCount(res.data.total)
        }
        else{
            message.warning('数据错误')
        }
        setLoading(false)
        }).catch(ex=>{
            setLoading(false)
            console.log('网络错误')
        })
    }

    const changePreVisitors = e=>{
        setPreVisitor(e.currentTarget.value)
    }

    const handleSubmit = () => {
        console.log(preVisitors)
        if(preVisitors==null || preVisitors==undefined || preVisitors.toString().trim()==""){
            message.warning("请输入")
            return
        }
        axios.post(`${config.APIS_URL.UPDATE_MGR_PRES}/${preVisitors}`).then(res=>{
            if(res.status==200 && res.data.code==200){
                message.success("提交成功")
                handlePullPreData()
            }
            else{
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }

    return (
        <div className='containter-box-1'>
            <div>今日访问次数：{visitorsCount}</div>
            <div>总访问次数：{totalVisitors}</div>
            <div>
                <div style={{display:'flex', margin: '10px 0', alignItems: 'center'}}>
                    <span>明日浏览量：</span>
                    <Input style={{width: '180px'}} value={preVisitors} onChange={(value)=>changePreVisitors(value)} type='number'></Input>
                </div>
                <div style={{display:'flex', margin: '10px 0', alignItems: 'center'}}>
                    <span>最近更新时间：</span>
                    <div style={{width: '180px'}} >{latestUPdate}</div>
                </div>
                <Button type='primary' onClick={()=>handleSubmit()}>提交</Button>
            </div>
        </div>
    );
}
 
export default VisitorsManager;