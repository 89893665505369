import React, {useEffect, useState} from 'react';
import axios from 'axios'
import config from "../constants"
import { message, Input, Button } from 'antd';
 
function PreVisitorsManager(){
    const [visitorsCount, setVisitorsCount] = useState(0)
    const [totalVisitors, setTotalVisitorsCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [preCount, setPreCount] = useState(null)
    const [latestUpdate, setLatestUpdate] = useState(null)

    useEffect(()=>{       
        handlePullPreData()
    }, [])

    const handlePullPreData = () =>{
        axios.post(config.APIS_URL.USER_MGR_PREVISIT, {action:4}).then(res=>{
            if(res.status==200 && res.data.code==200){
                let tmp = res.data.data
                setPreCount(tmp!=null>0?tmp.cnt:null)
                setLatestUpdate(tmp!=null?tmp.update_date:null)
                setVisitorsCount(res.data.real)
                setTotalVisitorsCount(res.data.realTotal)
            }
            else{
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }

    const handleSubmit = () => {
        console.log(preCount)
        if(preCount==null || preCount==undefined || preCount.toString().trim()==""){
            message.warning("请输入")
            return
        }
        axios.post(`${config.APIS_URL.USER_MGR_PREVISIT}`, {action: 1, count: preCount}).then(res=>{
            if(res.status==200 && res.data.code==200){
                message.success("提交成功")
                handlePullPreData()
            }
            else{
                message.error("网络错误")
            }
        }).catch(ex=>{
            message.error(ex)
        })
    }

    return (
        <div className='containter-box-1'>
            <div>今日访问次数：{visitorsCount}</div>
            <div>总访问次数：{totalVisitors}</div>
            <div>
                <div style={{display:'flex', margin: '10px 0', alignItems: 'center'}}>
                    <span>明日浏览量：</span>
                    <Input style={{width: '180px'}} value={preCount} onChange={(e)=>{setPreCount(e.currentTarget.value)}} type='number'></Input>
                </div>
                <div style={{display:'flex', margin: '10px 0', alignItems: 'center'}}>
                    <span>最近更新时间：</span>
                    <div style={{width: '180px'}} >{latestUpdate}</div>
                </div>
                <Button type='primary' onClick={()=>handleSubmit()}>提交</Button>
            </div>
        </div>
    );
}
 
export default PreVisitorsManager;