import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import {LockFilled, MediumOutlined, PauseCircleOutlined, MobileFilled} from "@ant-design/icons"
import "./signUp.less"
import config from "./constants.js"
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const SignUpForm = (props) => {
  const [account, setAccount] = useState(null)
  const [nickName, setNickName] = useState(null)
  const [password, setPassword] = useState(null)
  const [invitCode, setInvitCode] = useState(null)
  const [showLoading, setShowLoading] = useState(false)
  const {t} = useTranslation()

  const handleSubmit = () =>{
    if(account == null || account.trim()==""){
      message.warning(t("phoneNumberHolder2"))
      return
    }
    const reg = /^[1][3-9][0-9]{9}$/;
    if(!reg.test(account)){
      message.warning(t("phoneNumberError"))
      return
    }
    if(nickName == null || nickName.trim()==""){
      message.warning(t("nickNameHolder"))
      return
    }
    if(password == null || password.trim()==""){
      message.warning(t("passwordHolder"))
      return
    }
    if(invitCode == null || invitCode.trim()==""){
      message.warning(t("codeHolder"))
      return
    }

    //const { handleSignupEvent } = props
    setShowLoading(true)
    let param = {account: account.trim(), name: nickName.trim(), password: password.trim()}
    var arr = window.location.href.split("?")
    if(arr.length>1){
      var str = arr.find(f=>f.indexOf("code=")>-1)
      if(str){
        var code = str.split("&").find(f=>f.startsWith("code=")).replace("code=", '')
        param.parent_code = code
      }
    }
    if(invitCode){
      param.parent_code = invitCode
    }
    axios.post(config.APIS_URL.GIGN_UP_URL, param, { headers: { 'Content-Type': 'application/json'}}).then(res=>{
        if(res.data.code==200){
            message.success(t("signinSucc"))
            window.location.href="#/login"
        }
        else{
          message.info(res.data.detail)
        }
        //return {data:{status: 1, message: "服务器遇到一点问题"}}
      }).catch(ex=>{
        //return {data:{status:1, message: "用户名或密码错误"}}
      })
      setShowLoading(false)
  }

  return (
    <Spin spinning={showLoading} size='large' tip={t("waitTips")} className='signup-container'>
      <div className="sign-up" style={{ height: '100vh', width: '100vw', background: 'rgba(0,0,0,0.05)' }}>
        <div style={{ height: '50px' }}></div>
        <div className="logo-box">
          <div style={{textAlign:'center'}}>
            <div>{t("signin")} <span></span></div>
          </div>
        </div>
        <div className="login-container" style={{height: '420px'}}>
          <Form
            name="basic"
            className="ivu-form ivu-form-label-right"
            style={{ width: '300px', margin:'auto', marginTop: '50px' }}
            onFinish={(e)=>{handleSubmit(e)}}
          >
            <Form.Item
              name="phone"
              className="ivu-form-item ivu-form-item-required"
            >
              <Input prefix={<MobileFilled />} placeholder={t("phoneNumber")} onChange={(e)=>{setAccount(e.target.value)}} />
            </Form.Item>
            <Form.Item
              className="ivu-form-item ivu-form-item-required"
            >
              <Input prefix={<PauseCircleOutlined />} placeholder={t("nickName")} onChange={(e)=>{setNickName(e.target.value)}} />
            </Form.Item>

            <Form.Item
              className="ivu-form-item ivu-form-item-required"
            >
              <Input.Password prefix={<LockFilled />} placeholder={t("password")} onChange={(e)=>{setPassword(e.target.value)}} />
            </Form.Item>

            <Form.Item className="ivu-form-item ivu-form-item-required">
              <Input prefix={<MediumOutlined />} placeholder={t("invitationCode")} onChange={(e)=>{setInvitCode(e.target.value)}} />
            </Form.Item>

            <Form.Item>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Button type="primary" htmlType="submit" height="36px" className="ivu-btn ivu-btn-wechat-grren ivu-btn-long ivu-btn-large" style={{marginRight:'15px'}}>{t("signin")}</Button>
                    <div className="login-btn">
                        <a href="#/login">{t("loginTxt")}</a>
                    </div>
                </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default SignUpForm;
